import { useMutation, useQuery, useInfiniteQuery, UseMutationOptions, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { fetcher } from './../fetcher';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A timestamp in ISO format */
  DateTime: { input: string; output: string; }
  JSON: { input: Record<string, unknown>; output: Record<string, unknown>; }
  /** The JavaScript Date is an integer. Type represents date and time as number of milliseconds from start of the UNIX epoch. */
  Timestamp: { input: string; output: string; }
  URL: { input: string; output: string; }
  Void: { input: void; output: void; }
};

export type AcceptDraftEpisodeInvitationError = {
  __typename?: 'AcceptDraftEpisodeInvitationError';
  code: AcceptDraftEpisodeInvitationErrorCode;
  message: Scalars['String']['output'];
};

export enum AcceptDraftEpisodeInvitationErrorCode {
  InvalidEpisodeId = 'INVALID_EPISODE_ID',
  Unknown = 'UNKNOWN'
}

export type AcceptDraftEpisodeInvitationMutationInput = {
  episodeId: Scalars['ID']['input'];
};

export type AcceptDraftEpisodeInvitationMutationResponse = {
  __typename?: 'AcceptDraftEpisodeInvitationMutationResponse';
  episode?: Maybe<MinifiedEpisode>;
  error?: Maybe<Array<AcceptDraftEpisodeInvitationError>>;
};

export type AppUpdateData = {
  __typename?: 'AppUpdateData';
  minimumBuildNumber: Scalars['Int']['output'];
  updateUrl: Scalars['String']['output'];
};

export type AuthenticateWithProviderInput = {
  /** The provider a user is authenticating with */
  provider: AuthenticationProvider;
  /** Open object for provider-specific metadata needed to authenticate a user */
  providerMetadata: ProviderMetadata;
  /** The identity token provided by the provider to authenticate this user */
  token: Scalars['String']['input'];
};

export type AuthenticateWithProviderResponse = {
  __typename?: 'AuthenticateWithProviderResponse';
  error?: Maybe<Array<AuthenticationError>>;
  /** Boolean to indicate if a user just linked a non-device authenticator */
  isNewlyLinked?: Maybe<Scalars['Boolean']['output']>;
  jwt?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export enum AuthenticationError {
  Unknown = 'UNKNOWN'
}

export enum AuthenticationProvider {
  Apple = 'APPLE',
  Device = 'DEVICE',
  Google = 'GOOGLE',
  Supabase = 'SUPABASE'
}

export type ButtonCta = {
  __typename?: 'ButtonCTA';
  params?: Maybe<Scalars['JSON']['output']>;
  route: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type Character = {
  __typename?: 'Character';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  contractAddress?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inventory?: Maybe<Array<InventoryItem>>;
  isCreated?: Maybe<Scalars['Boolean']['output']>;
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  tbaAddress?: Maybe<Scalars['String']['output']>;
  tokenId?: Maybe<Scalars['String']['output']>;
};

export type CharacterAttribute = {
  __typename?: 'CharacterAttribute';
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
  ref: Scalars['String']['output'];
};

export type CharacterAttributeValue = {
  __typename?: 'CharacterAttributeValue';
  /** @deprecated Character attributes are no longer global */
  description: Scalars['String']['output'];
  /** @deprecated Character attributes are no longer global */
  name: Scalars['String']['output'];
  /** @deprecated Character attributes are no longer global */
  percentageChange?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Character attributes are no longer global */
  ref: Scalars['String']['output'];
  /** @deprecated Character attributes are no longer global */
  value: Scalars['Int']['output'];
};

export type ClearUserEpisodesInSeriesError = {
  __typename?: 'ClearUserEpisodesInSeriesError';
  code: ClearUserEpisodesInSeriesErrorCode;
  message: Scalars['String']['output'];
};

export enum ClearUserEpisodesInSeriesErrorCode {
  Unknown = 'UNKNOWN'
}

export type ClearUserEpisodesInSeriesInput = {
  seriesId: Scalars['ID']['input'];
};

export type ClearUserEpisodesInSeriesResponse = {
  __typename?: 'ClearUserEpisodesInSeriesResponse';
  error?: Maybe<Array<ClearUserEpisodesInSeriesError>>;
};

export enum CoinSide {
  Heads = 'HEADS',
  Tails = 'TAILS'
}

export type CoinTossInteractionInput = {
  interactionId: Scalars['String']['input'];
  type: CoinTossInteractionType;
  userEpisodeId: Scalars['ID']['input'];
};

export enum CoinTossInteractionType {
  /** The user is asked to confirm their choice when they lose the coin toss */
  ConfirmFail = 'CONFIRM_FAIL',
  Heads = 'HEADS',
  Tails = 'TAILS'
}

export type CoinTossResult = {
  __typename?: 'CoinTossResult';
  confirmed: Scalars['Boolean']['output'];
  result: CoinSide;
  selectedChoice: CoinSide;
  successful: Scalars['Boolean']['output'];
};

export type ContinueEpisodeInput = {
  userEpisodeId: Scalars['ID']['input'];
};

export type CreateAiEpisodeJobError = {
  __typename?: 'CreateAiEpisodeJobError';
  code: CreateAiEpisodeJobErrorCode;
  message: Scalars['String']['output'];
};

export enum CreateAiEpisodeJobErrorCode {
  InvalidInput = 'INVALID_INPUT',
  Unknown = 'UNKNOWN'
}

export type CreateAiEpisodeJobInput = {
  characterId?: InputMaybe<Scalars['String']['input']>;
  episodeTemplateId?: InputMaybe<Scalars['ID']['input']>;
  props: Scalars['JSON']['input'];
  seriesId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateAiEpisodeJobResponse = {
  __typename?: 'CreateAiEpisodeJobResponse';
  error?: Maybe<Array<CreateAiEpisodeJobError>>;
  jobId: Scalars['ID']['output'];
};

export type CreateCharacterError = {
  __typename?: 'CreateCharacterError';
  code: CreateCharacterErrorCode;
  message: Scalars['String']['output'];
};

export enum CreateCharacterErrorCode {
  InvalidInput = 'INVALID_INPUT',
  Unknown = 'UNKNOWN'
}

export type CreateCharacterForm = {
  __typename?: 'CreateCharacterForm';
  form: EpisodeTemplateForm;
  name: Scalars['String']['output'];
};

export type CreateCharacterImageInput = {
  props: Scalars['JSON']['input'];
};

export type CreateCharacterImageResponse = {
  __typename?: 'CreateCharacterImageResponse';
  description?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Array<CreateCharacterError>>;
  images?: Maybe<Array<Scalars['String']['output']>>;
};

export type CreateCharacterInput = {
  avatarImageUrl: Scalars['String']['input'];
  props: Scalars['JSON']['input'];
};

export type CreateCharacterResponse = {
  __typename?: 'CreateCharacterResponse';
  character?: Maybe<Character>;
  error?: Maybe<Array<CreateCharacterError>>;
};

export type CreateDiscoverySectionInput = {
  name: Scalars['String']['input'];
};

export type CreateDiscoverySectionResponse = {
  __typename?: 'CreateDiscoverySectionResponse';
  error?: Maybe<Array<DiscoveryError>>;
  section?: Maybe<DiscoverySection>;
};

export enum CreateEmulatorSessionErrorCode {
  Unknown = 'UNKNOWN'
}

export type CreateEmulatorSessionResponse = {
  __typename?: 'CreateEmulatorSessionResponse';
  error?: Maybe<Array<CreateEmulatorSessionErrorCode>>;
  session?: Maybe<EmulatorSession>;
};

export type CreateEpisodeError = DisplayableError & {
  __typename?: 'CreateEpisodeError';
  code: CreateEpisodeErrorCode;
  message: Scalars['String']['output'];
  source?: Maybe<Scalars['String']['output']>;
};

export enum CreateEpisodeErrorCode {
  InvalidInput = 'INVALID_INPUT',
  Unauthorized = 'UNAUTHORIZED',
  Unknown = 'UNKNOWN'
}

export type CreateEpisodeInput = {
  coverImageUrl?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  seriesId: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CreateEpisodeResponse = {
  __typename?: 'CreateEpisodeResponse';
  episode?: Maybe<StudioEpisode>;
  error?: Maybe<Array<CreateEpisodeError>>;
};

export type CreateFactoryBranchError = {
  __typename?: 'CreateFactoryBranchError';
  code: CreateFactoryBranchErrorCode;
  message: Scalars['String']['output'];
};

export enum CreateFactoryBranchErrorCode {
  InvalidInput = 'INVALID_INPUT',
  NotFound = 'NOT_FOUND',
  Unknown = 'UNKNOWN'
}

export type CreateFactoryBranchInput = {
  coverImage: Scalars['String']['input'];
  fields: Array<FieldValueInput>;
  storyFactoryId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateFactoryBranchResponse = {
  __typename?: 'CreateFactoryBranchResponse';
  error?: Maybe<Array<CreateFactoryBranchError>>;
  storyFactoryBranch?: Maybe<StoryFactoryBranch>;
};

export type CreateFactoryError = {
  __typename?: 'CreateFactoryError';
  code: CreateFactoryErrorCode;
  message: Scalars['String']['output'];
};

export enum CreateFactoryErrorCode {
  InvalidInput = 'INVALID_INPUT',
  Unknown = 'UNKNOWN'
}

export type CreateFactoryInput = {
  factoryStrategy: Scalars['String']['input'];
  fields: Array<FieldDefinitionInput>;
  prompt: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateFactoryResponse = {
  __typename?: 'CreateFactoryResponse';
  error?: Maybe<Array<CreateFactoryError>>;
  storyFactory?: Maybe<StoryFactory>;
};

export enum CreateSeriesErrorCode {
  Unknown = 'UNKNOWN'
}

export type CreateSeriesInput = {
  coverImageUrl?: InputMaybe<Scalars['String']['input']>;
  /** Creation of Series includes creating an untitled episode */
  createFirstEpisode?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  genreId?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  worldId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateSeriesResponse = {
  __typename?: 'CreateSeriesResponse';
  error?: Maybe<Array<CreateSeriesErrorCode>>;
  firstEpisodeId?: Maybe<Scalars['ID']['output']>;
  series?: Maybe<Series>;
};

export type CreateSeriesSatelliteDataOption = {
  __typename?: 'CreateSeriesSatelliteDataOption';
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export enum CreateSeriesSatelliteDataQueryErrorCode {
  Unknown = 'UNKNOWN'
}

export type CreateSeriesSatelliteDataResponse = {
  __typename?: 'CreateSeriesSatelliteDataResponse';
  error?: Maybe<Array<CreateSeriesSatelliteDataQueryErrorCode>>;
  genres?: Maybe<Array<CreateSeriesSatelliteDataOption>>;
  worlds?: Maybe<Array<CreateSeriesSatelliteDataOption>>;
};

export type CreateSignedUploadUrResponse = {
  __typename?: 'CreateSignedUploadUrResponse';
  error?: Maybe<Array<CreateSignedUploadUrlError>>;
  url?: Maybe<Scalars['String']['output']>;
};

export type CreateSignedUploadUrlError = DisplayableError & {
  __typename?: 'CreateSignedUploadUrlError';
  code: CreateSignedUploadUrlErrorCode;
  message: Scalars['String']['output'];
  source: Scalars['String']['output'];
};

export enum CreateSignedUploadUrlErrorCode {
  ProviderError = 'PROVIDER_ERROR',
  Unknown = 'UNKNOWN'
}

export type CreateSignedUploadUrlInput = {
  fileName: Scalars['String']['input'];
  objectType: StorageObjectType;
};

export type CreateUserEpisodeError = {
  __typename?: 'CreateUserEpisodeError';
  code: CreateUserEpisodeErrorCode;
  message: Scalars['String']['output'];
};

export enum CreateUserEpisodeErrorCode {
  DuplicateEntry = 'DUPLICATE_ENTRY',
  EpisodeLocked = 'EPISODE_LOCKED',
  EpisodeNotFound = 'EPISODE_NOT_FOUND'
}

export type CreateUserEpisodeResponse = {
  __typename?: 'CreateUserEpisodeResponse';
  data?: Maybe<UserEpisode>;
  error?: Maybe<Array<CreateUserEpisodeError>>;
};

export type CreditActivity = {
  __typename?: 'CreditActivity';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  quantity: Scalars['Int']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type: CreditActivityType;
};

export type CreditActivityInput = {
  filterType?: InputMaybe<CreditActivityType>;
};

export enum CreditActivityType {
  Expense = 'EXPENSE',
  Income = 'INCOME'
}

export enum DeleteAccountError {
  Unknown = 'UNKNOWN'
}

export type DeleteAccountResponse = {
  __typename?: 'DeleteAccountResponse';
  error?: Maybe<Array<DeleteAccountError>>;
};

export type DeleteDiscoverySectionInput = {
  id: Scalars['ID']['input'];
};

export type DeleteDiscoverySectionResponse = {
  __typename?: 'DeleteDiscoverySectionResponse';
  error?: Maybe<Array<DiscoveryError>>;
};

export enum DeviceType {
  Android = 'ANDROID',
  Ios = 'IOS',
  Unknown = 'UNKNOWN'
}

export type Dice = {
  __typename?: 'Dice';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  sides: Scalars['Int']['output'];
};

export type DiceRollInteractionInput = {
  interactionId: Scalars['String']['input'];
  userEpisodeId: Scalars['ID']['input'];
};

export type DiceRollResult = {
  __typename?: 'DiceRollResult';
  dice: Dice;
  result: Scalars['Int']['output'];
};

export type DiscoveryError = DisplayableError & {
  __typename?: 'DiscoveryError';
  code: DiscoveryErrorCode;
  message: Scalars['String']['output'];
  source: Scalars['String']['output'];
};

export enum DiscoveryErrorCode {
  InvalidInput = 'INVALID_INPUT',
  Unknown = 'UNKNOWN'
}

export type DiscoveryInput = {
  includeEmptySections?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DiscoveryResponse = {
  __typename?: 'DiscoveryResponse';
  error?: Maybe<Array<DiscoveryError>>;
  sections?: Maybe<Array<DiscoverySection>>;
};

export type DiscoverySection = {
  __typename?: 'DiscoverySection';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  order: Scalars['Int']['output'];
  payload: DiscoverySectionPayload;
  type: DiscoverySectionType;
};

export type DiscoverySectionBanner = {
  __typename?: 'DiscoverySectionBanner';
  backgroundUrl: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  params?: Maybe<Scalars['JSON']['output']>;
  route: Scalars['String']['output'];
  size?: Maybe<HeroImageSize>;
  title: Scalars['String']['output'];
};

export type DiscoverySectionCategories = {
  __typename?: 'DiscoverySectionCategories';
  categories: Array<StoryCategory>;
};

export type DiscoverySectionContinuePlaying = {
  __typename?: 'DiscoverySectionContinuePlaying';
  recentEpisodes: Array<RecentEpisode>;
};

export type DiscoverySectionGenres = {
  __typename?: 'DiscoverySectionGenres';
  genres: Array<SeriesGenre>;
};

export type DiscoverySectionHeroImage = {
  __typename?: 'DiscoverySectionHeroImage';
  badge?: Maybe<Scalars['String']['output']>;
  button: ButtonCta;
  description?: Maybe<Scalars['String']['output']>;
  imageUrl: Scalars['String']['output'];
  size?: Maybe<HeroImageSize>;
  title: Scalars['String']['output'];
};

export type DiscoverySectionMutations = {
  __typename?: 'DiscoverySectionMutations';
  createSectionSeries: CreateDiscoverySectionResponse;
  deleteSection: DeleteDiscoverySectionResponse;
  updateSectionSeries: UpdateDiscoverySectionResponse;
};


export type DiscoverySectionMutationsCreateSectionSeriesArgs = {
  input: CreateDiscoverySectionInput;
};


export type DiscoverySectionMutationsDeleteSectionArgs = {
  id: Scalars['ID']['input'];
};


export type DiscoverySectionMutationsUpdateSectionSeriesArgs = {
  input: UpdateDiscoverySectionInput;
};

export type DiscoverySectionPayload = DiscoverySectionBanner | DiscoverySectionCategories | DiscoverySectionGenres | DiscoverySectionHeroImage | DiscoverySectionRecentEpisodes | DiscoverySectionSeries | DiscoverySectionStoryTemplates;

export type DiscoverySectionRecentEpisodes = {
  __typename?: 'DiscoverySectionRecentEpisodes';
  recentEpisodes: Array<RecentEpisode>;
};

export type DiscoverySectionSeries = {
  __typename?: 'DiscoverySectionSeries';
  onlyForKids: Scalars['Boolean']['output'];
  series: Array<Series>;
};

export type DiscoverySectionStoryTemplates = {
  __typename?: 'DiscoverySectionStoryTemplates';
  templates: Array<Template>;
};

export enum DiscoverySectionType {
  Banner = 'BANNER',
  Categories = 'CATEGORIES',
  FullScreenHeroImage = 'FULL_SCREEN_HERO_IMAGE',
  Genres = 'GENRES',
  HeroImage = 'HERO_IMAGE',
  RecentEpisodes = 'RECENT_EPISODES',
  Series = 'SERIES',
  StoryTemplates = 'STORY_TEMPLATES'
}

export type DisplayableError = {
  message: Scalars['String']['output'];
  source?: Maybe<Scalars['String']['output']>;
};

export type DraftEpisodeVersion = {
  __typename?: 'DraftEpisodeVersion';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  studioFlow: Scalars['JSON']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type EmulatorSession = {
  __typename?: 'EmulatorSession';
  jwt: Scalars['String']['output'];
};

export enum EndOfEpisodeAction {
  PlayNextEpisode = 'PLAY_NEXT_EPISODE',
  ReplayEpisode = 'REPLAY_EPISODE',
  ReplaySeries = 'REPLAY_SERIES'
}

export type Episode = {
  __typename?: 'Episode';
  /** episode description */
  description: Scalars['String']['output'];
  /** episode play time duration in seconds */
  durationInSeconds: Scalars['Int']['output'];
  /** Indicates if the episode has a draft version */
  hasDraft: Scalars['Boolean']['output'];
  /** Indicates if the episode has a new version */
  hasNewVersion: Scalars['Boolean']['output'];
  /** unique identifier */
  id: Scalars['ID']['output'];
  /** Indicates if the episode is new for the series */
  isNew: Scalars['Boolean']['output'];
  /** media assets for the series marketing materials */
  media: EpisodeMedia;
  /** episode numeric order */
  order: Scalars['Int']['output'];
  /** Total number of plays for the episode */
  playsCount: Scalars['Int']['output'];
  /** Reference */
  ref?: Maybe<Scalars['String']['output']>;
  /** Series id */
  seriesId: Scalars['ID']['output'];
  /** availability status */
  status: EpisodeStatus;
  /** episode title */
  title: Scalars['String']['output'];
  /** How much gems is needed to unlock the episode */
  unlockPrice?: Maybe<Scalars['Int']['output']>;
  /**
   * User Episode ID, if exists
   * @deprecated userEpisodeId is deprecated. Use userEpisodeId inside of userMetrices instead.
   */
  userEpisodeId?: Maybe<Scalars['ID']['output']>;
  /** user specific virtual attributes */
  userMetrics?: Maybe<UserEpisodeMetrics>;
};

export type EpisodeGenerationJob = {
  __typename?: 'EpisodeGenerationJob';
  episodeId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  seriesId?: Maybe<Scalars['ID']['output']>;
  status: EpisodeGenerationStatus;
};

export enum EpisodeGenerationStatus {
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Playable = 'PLAYABLE',
  Succeeded = 'SUCCEEDED'
}

export enum EpisodeGenerationStatusError {
  NotFound = 'NOT_FOUND',
  Unknown = 'UNKNOWN'
}

export type EpisodeGenerationStatusInput = {
  /** Job ID of the generation to queue status for */
  jobId: Scalars['ID']['input'];
};

export type EpisodeGenerationStatusResponse = {
  __typename?: 'EpisodeGenerationStatusResponse';
  error?: Maybe<Array<EpisodeGenerationStatusError>>;
  job?: Maybe<EpisodeGenerationJob>;
};

export type EpisodeMedia = {
  __typename?: 'EpisodeMedia';
  main: Scalars['String']['output'];
  thumbnail: Scalars['String']['output'];
};

export enum EpisodeStatus {
  Draft = 'DRAFT',
  NewVersionDraft = 'NEW_VERSION_DRAFT',
  Published = 'PUBLISHED'
}

export type EpisodeTemplate = {
  __typename?: 'EpisodeTemplate';
  form: EpisodeTemplateForm;
  id: Scalars['ID']['output'];
};

export type EpisodeTemplateForm = {
  __typename?: 'EpisodeTemplateForm';
  fields: Array<EpisodeTemplateFormField>;
};

export type EpisodeTemplateFormField = {
  __typename?: 'EpisodeTemplateFormField';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  options?: Maybe<Array<EpisodeTemplateFormFieldOption>>;
  propertyName: Scalars['String']['output'];
  type: EpisodeTemplateFormFieldType;
};

export type EpisodeTemplateFormFieldOption = {
  __typename?: 'EpisodeTemplateFormFieldOption';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum EpisodeTemplateFormFieldType {
  Number = 'NUMBER',
  Select = 'SELECT',
  String = 'STRING'
}

export type EpisodeTemplateQueryError = {
  __typename?: 'EpisodeTemplateQueryError';
  code: EpisodeTemplateQueryErrorCode;
  message: Scalars['String']['output'];
};

export enum EpisodeTemplateQueryErrorCode {
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  NotFound = 'NOT_FOUND'
}

export type EpisodeTemplateResponse = {
  __typename?: 'EpisodeTemplateResponse';
  error?: Maybe<Array<EpisodeTemplateQueryError>>;
  template?: Maybe<EpisodeTemplate>;
};

export enum EpisodesErrorCode {
  InvalidInput = 'INVALID_INPUT',
  Unknown = 'UNKNOWN'
}

export type EpisodesQueryError = DisplayableError & {
  __typename?: 'EpisodesQueryError';
  code: EpisodesErrorCode;
  message: Scalars['String']['output'];
  source: Scalars['String']['output'];
};

export type EpisodesQueryInput = {
  /** @deprecated Not used anymore */
  episodeRef?: InputMaybe<Scalars['String']['input']>;
  includeDrafts?: InputMaybe<Scalars['Boolean']['input']>;
  seriesId?: InputMaybe<Scalars['ID']['input']>;
};

export type EpisodesResponse = {
  __typename?: 'EpisodesResponse';
  episodes: Array<Episode>;
  error?: Maybe<Array<EpisodesQueryError>>;
};

export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  /** Feature flag key */
  key: Scalars['String']['output'];
  /** Feature flag state */
  state: FeatureFlagState;
  /** Feature flag value */
  value?: Maybe<Scalars['JSON']['output']>;
};

export type FeatureFlagError = DisplayableError & {
  __typename?: 'FeatureFlagError';
  code: FeatureFlagErrorCode;
  message: Scalars['String']['output'];
  source: Scalars['String']['output'];
};

export enum FeatureFlagErrorCode {
  InvalidInput = 'INVALID_INPUT',
  ProviderError = 'PROVIDER_ERROR',
  Unknown = 'UNKNOWN'
}

export enum FeatureFlagState {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED'
}

export type FeatureFlagUserContextInput = {
  /** the browser users agent */
  appBuildNumber: Scalars['Int']['input'];
};

export type FieldDefinition = {
  __typename?: 'FieldDefinition';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  options?: Maybe<Array<Scalars['String']['output']>>;
  required: Scalars['Boolean']['output'];
  type: FieldType;
};

export type FieldDefinitionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  options?: InputMaybe<Array<Scalars['String']['input']>>;
  required: Scalars['Boolean']['input'];
  type: FieldType;
};

export enum FieldType {
  Number = 'NUMBER',
  Select = 'SELECT',
  Text = 'TEXT'
}

export type FieldValue = {
  __typename?: 'FieldValue';
  fieldId: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

export type FieldValueInput = {
  fieldId: Scalars['ID']['input'];
  value: Scalars['String']['input'];
};

export type GameCharacter = {
  __typename?: 'GameCharacter';
  name: Scalars['String']['output'];
  role: GameRole;
};

export enum GameRole {
  Narrator = 'NARRATOR',
  NonPlayableCharacter = 'NON_PLAYABLE_CHARACTER',
  PlayableCharacter = 'PLAYABLE_CHARACTER'
}

export type GetFeatureFlagsInput = {
  /** additional context to be used for evaluation or segmentation */
  userContext: FeatureFlagUserContextInput;
};

export type GetFeatureFlagsResponse = {
  __typename?: 'GetFeatureFlagsResponse';
  error?: Maybe<Array<FeatureFlagError>>;
  featureFlags: Array<FeatureFlag>;
};

export type GetUserEpisodeError = {
  __typename?: 'GetUserEpisodeError';
  code: GetUserEpisodeErrorCode;
  message: Scalars['String']['output'];
};

export enum GetUserEpisodeErrorCode {
  InsufficientCredits = 'INSUFFICIENT_CREDITS',
  NotFound = 'NOT_FOUND',
  PriceMismatch = 'PRICE_MISMATCH'
}

export type HeroImageSize = {
  __typename?: 'HeroImageSize';
  height?: Maybe<Scalars['Int']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

export enum ImpersonateUserError {
  NotFound = 'NOT_FOUND',
  Unknown = 'UNKNOWN'
}

export type ImpersonateUserInput = {
  /** The auth token to check fo */
  authToken: Scalars['String']['input'];
  /** The userId to create a session for */
  impersonationUserId: Scalars['ID']['input'];
};

export type ImpersonateUserResponse = {
  __typename?: 'ImpersonateUserResponse';
  error?: Maybe<Array<ImpersonateUserError>>;
  jwt?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type Interaction = {
  __typename?: 'Interaction';
  coinToss: InteractionResponse;
  continueEpisode: InteractionResponse;
  playerInput: InteractionResponse;
  rollDices: InteractionResponse;
  singleSelect: InteractionResponse;
  triggerItemAction: InteractionResponse;
};


export type InteractionCoinTossArgs = {
  input: CoinTossInteractionInput;
};


export type InteractionContinueEpisodeArgs = {
  input: ContinueEpisodeInput;
};


export type InteractionPlayerInputArgs = {
  input: PlayerInputInteractionInput;
};


export type InteractionRollDicesArgs = {
  input: DiceRollInteractionInput;
};


export type InteractionSingleSelectArgs = {
  input: SingleSelectInteractionInput;
};


export type InteractionTriggerItemActionArgs = {
  input: TriggerItemActionInput;
};

export type InteractionError = {
  __typename?: 'InteractionError';
  code: InteractionErrorCode;
  message: Scalars['String']['output'];
};

export enum InteractionErrorCode {
  InvalidInteraction = 'INVALID_INTERACTION',
  NotFound = 'NOT_FOUND'
}

export type InteractionResponse = {
  __typename?: 'InteractionResponse';
  error?: Maybe<Array<InteractionError>>;
  hud?: Maybe<Array<UserEpisodeHudItem>>;
  messages?: Maybe<Array<UserEpisodeMessage>>;
};

export type InventoryItem = {
  __typename?: 'InventoryItem';
  action?: Maybe<InventoryItemAction>;
  category: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imageUrl: Scalars['String']['output'];
  itemId: Scalars['String']['output'];
  location?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<InventoryItemMetadata>;
  name: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
};

export type InventoryItemAction = {
  __typename?: 'InventoryItemAction';
  consumable: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  ref: Scalars['String']['output'];
};

export type InventoryItemEpisodeDetails = {
  __typename?: 'InventoryItemEpisodeDetails';
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
};

export type InventoryItemMetadata = {
  __typename?: 'InventoryItemMetadata';
  episode?: Maybe<Array<InventoryItemEpisodeDetails>>;
  owner?: Maybe<Array<InventoryItemOwnerDetails>>;
  series?: Maybe<Array<InventoryItemSeriesDetails>>;
};

export type InventoryItemOwnerDetails = {
  __typename?: 'InventoryItemOwnerDetails';
  name?: Maybe<Scalars['String']['output']>;
};

export type InventoryItemQueryError = DisplayableError & {
  __typename?: 'InventoryItemQueryError';
  code: InventoryItemQueryErrorCode;
  message: Scalars['String']['output'];
  source: Scalars['String']['output'];
};

export enum InventoryItemQueryErrorCode {
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED',
  Unknown = 'UNKNOWN'
}

export type InventoryItemQueryInput = {
  characterId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
};

export type InventoryItemResponse = {
  __typename?: 'InventoryItemResponse';
  error?: Maybe<Array<InventoryItemQueryError>>;
  inventoryItem?: Maybe<InventoryItem>;
};

export type InventoryItemSeriesDetails = {
  __typename?: 'InventoryItemSeriesDetails';
  name?: Maybe<Scalars['String']['output']>;
};

export type InventoryQueryError = DisplayableError & {
  __typename?: 'InventoryQueryError';
  code: InventoryQueryErrorCode;
  message: Scalars['String']['output'];
  source: Scalars['String']['output'];
};

export enum InventoryQueryErrorCode {
  InvalidInput = 'INVALID_INPUT',
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED',
  Unknown = 'UNKNOWN'
}

export type InventoryQueryInput = {
  characterId?: InputMaybe<Scalars['ID']['input']>;
  userEpisodeId?: InputMaybe<Scalars['ID']['input']>;
};

export type InventoryResponse = {
  __typename?: 'InventoryResponse';
  error?: Maybe<Array<InventoryQueryError>>;
  items?: Maybe<Array<InventoryItem>>;
};

export type LikeSeriesError = DisplayableError & {
  __typename?: 'LikeSeriesError';
  code: LikeSeriesErrorCode;
  message: Scalars['String']['output'];
  source: Scalars['String']['output'];
};

export enum LikeSeriesErrorCode {
  InvalidInput = 'INVALID_INPUT',
  SeriesNotFound = 'SERIES_NOT_FOUND'
}

export type LikeSeriesInput = {
  like: Scalars['Boolean']['input'];
  seriesId: Scalars['ID']['input'];
};

export type LikeSeriesResponse = {
  __typename?: 'LikeSeriesResponse';
  error?: Maybe<Array<LikeSeriesError>>;
};

export enum MaestroEpisodesErrorCode {
  InvalidInput = 'INVALID_INPUT',
  Unauthorized = 'UNAUTHORIZED',
  Unknown = 'UNKNOWN'
}

export type MaestroEpisodesQueryError = DisplayableError & {
  __typename?: 'MaestroEpisodesQueryError';
  code: MaestroEpisodesErrorCode;
  message: Scalars['String']['output'];
  source: Scalars['String']['output'];
};

export type MaestroEpisodesQueryInput = {
  /** boolean to force drafts first */
  draftsFirst?: InputMaybe<Scalars['Boolean']['input']>;
  /** pagination limit */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** pagination offset */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Series ID to filter results by */
  seriesId?: InputMaybe<Scalars['ID']['input']>;
};

export type MaestroEpisodesResponse = {
  __typename?: 'MaestroEpisodesResponse';
  episodes?: Maybe<Array<StudioEpisode>>;
  error?: Maybe<Array<MaestroEpisodesQueryError>>;
  metadata?: Maybe<PaginatedResultMetadata>;
};

export type MaestroSeries = {
  __typename?: 'MaestroSeries';
  /** the name of the author of the series */
  authorName?: Maybe<Scalars['String']['output']>;
  /** the description of the series */
  description?: Maybe<Scalars['String']['output']>;
  /** genre details */
  genre?: Maybe<MaestroSeriesGenreDetails>;
  /** genre id */
  genreId?: Maybe<Scalars['String']['output']>;
  /** unique identifier */
  id: Scalars['ID']['output'];
  /** likes count */
  likesCount: Scalars['Int']['output'];
  /** media assets for the series marketing materials */
  media?: Maybe<SeriesMedia>;
  /** the overview of the series */
  overview?: Maybe<Scalars['String']['output']>;
  /** Play count */
  playsCount: Scalars['Int']['output'];
  /** title of the series */
  title?: Maybe<Scalars['String']['output']>;
  /** world details */
  world?: Maybe<MaestroSeriesWorldDetails>;
  /** id of the world assigned to this series */
  worldId?: Maybe<Scalars['String']['output']>;
};

export enum MaestroSeriesErrorCode {
  InvalidInput = 'INVALID_INPUT',
  Unauthorized = 'UNAUTHORIZED',
  Unknown = 'UNKNOWN'
}

export type MaestroSeriesGenreDetails = {
  __typename?: 'MaestroSeriesGenreDetails';
  /** unique identifier */
  id: Scalars['ID']['output'];
  /** title of the genre */
  title: Scalars['String']['output'];
};

export type MaestroSeriesQueryError = DisplayableError & {
  __typename?: 'MaestroSeriesQueryError';
  code: MaestroSeriesErrorCode;
  message: Scalars['String']['output'];
  source: Scalars['String']['output'];
};

export type MaestroSeriesQueryInput = {
  /** Series ID to filter results by */
  seriesId: Scalars['ID']['input'];
};

export type MaestroSeriesResponse = {
  __typename?: 'MaestroSeriesResponse';
  error?: Maybe<Array<MaestroSeriesQueryError>>;
  series?: Maybe<MaestroSeries>;
};

export type MaestroSeriesWorldDetails = {
  __typename?: 'MaestroSeriesWorldDetails';
  /** unique identifier */
  id: Scalars['ID']['output'];
  /** title of the world */
  title: Scalars['String']['output'];
};

export type MinifiedEpisode = {
  __typename?: 'MinifiedEpisode';
  author?: Maybe<Scalars['String']['output']>;
  coverUrl?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  hasDraft: Scalars['Boolean']['output'];
  hasNewVersion: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isAuthor: Scalars['Boolean']['output'];
  playsCount: Scalars['Int']['output'];
  seriesId: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptDraftEpisodeInvitation: AcceptDraftEpisodeInvitationMutationResponse;
  authenticateWithProvider: AuthenticateWithProviderResponse;
  clearUserEpisodesInSeries: ClearUserEpisodesInSeriesResponse;
  createAiEpisodeJob: CreateAiEpisodeJobResponse;
  createCharacter: CreateCharacterResponse;
  createCharacterImage: CreateCharacterImageResponse;
  createEmulatorSession: CreateEmulatorSessionResponse;
  createEpisode: CreateEpisodeResponse;
  createFactory: CreateFactoryResponse;
  createFactoryBranch: CreateFactoryBranchResponse;
  createSeries: CreateSeriesResponse;
  createUploadUrl: CreateSignedUploadUrResponse;
  createUserEpisode: CreateUserEpisodeResponse;
  deleteAccount: DeleteAccountResponse;
  discoverySection: DiscoverySectionMutations;
  impersonateUser: ImpersonateUserResponse;
  interaction: Interaction;
  likeSeries: LikeSeriesResponse;
  logout?: Maybe<Scalars['Void']['output']>;
  publishEpisode: PublishEpisodeResponse;
  realtimeToken: RealtimeTokenResponse;
  setMessageViewed: SetMessageViewedResponse;
  updateEpisode: UpdateEpisodeResponse;
  updateFactory: UpdateFactoryResponse;
  updateFactoryBranch: UpdateFactoryBranchResponse;
  updateNotificationPreferences: UpdateNotificationPreferencesResponse;
  updateSeries: UpdateSeriesResponse;
  updateSeriesRpgConfig: RpgConfigResponse;
  updateUser: UpdateUserResponse;
  verifyReceipt: VerifyReceiptResponse;
};


export type MutationAcceptDraftEpisodeInvitationArgs = {
  input: AcceptDraftEpisodeInvitationMutationInput;
};


export type MutationAuthenticateWithProviderArgs = {
  input: AuthenticateWithProviderInput;
};


export type MutationClearUserEpisodesInSeriesArgs = {
  input: ClearUserEpisodesInSeriesInput;
};


export type MutationCreateAiEpisodeJobArgs = {
  input: CreateAiEpisodeJobInput;
};


export type MutationCreateCharacterArgs = {
  input: CreateCharacterInput;
};


export type MutationCreateCharacterImageArgs = {
  input: CreateCharacterImageInput;
};


export type MutationCreateEpisodeArgs = {
  input: CreateEpisodeInput;
};


export type MutationCreateFactoryArgs = {
  input: CreateFactoryInput;
};


export type MutationCreateFactoryBranchArgs = {
  input: CreateFactoryBranchInput;
};


export type MutationCreateSeriesArgs = {
  input: CreateSeriesInput;
};


export type MutationCreateUploadUrlArgs = {
  input: CreateSignedUploadUrlInput;
};


export type MutationCreateUserEpisodeArgs = {
  episodeId: Scalars['ID']['input'];
};


export type MutationImpersonateUserArgs = {
  input: ImpersonateUserInput;
};


export type MutationLikeSeriesArgs = {
  input: LikeSeriesInput;
};


export type MutationPublishEpisodeArgs = {
  input: PublishEpisodeInput;
};


export type MutationSetMessageViewedArgs = {
  input: SetMessageViewedInput;
};


export type MutationUpdateEpisodeArgs = {
  input: UpdateEpisodeInput;
};


export type MutationUpdateFactoryArgs = {
  id: Scalars['ID']['input'];
  input: UpdateFactoryInput;
};


export type MutationUpdateFactoryBranchArgs = {
  id: Scalars['ID']['input'];
  input: UpdateFactoryBranchInput;
};


export type MutationUpdateNotificationPreferencesArgs = {
  input: UpdateNotificationPreferencesInput;
};


export type MutationUpdateSeriesArgs = {
  input: UpdateSeriesInput;
};


export type MutationUpdateSeriesRpgConfigArgs = {
  input: UpdateSeriesRpgConfigInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationVerifyReceiptArgs = {
  input: VerifyReceiptInput;
};

export type MyStoriesQueryResponse = {
  __typename?: 'MyStoriesQueryResponse';
  stories: Array<Story>;
};

export type NotificationPreference = {
  __typename?: 'NotificationPreference';
  displayName: Scalars['String']['output'];
  displayOrder: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
};

export enum NotificationPreferencesError {
  ProviderError = 'PROVIDER_ERROR',
  Unauthenticated = 'UNAUTHENTICATED',
  Unknown = 'UNKNOWN'
}

export type NotificationPreferencesResponse = {
  __typename?: 'NotificationPreferencesResponse';
  error?: Maybe<Array<NotificationPreferencesError>>;
  preferences: Array<NotificationPreference>;
};

export type PageCursor = {
  __typename?: 'PageCursor';
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  pageSize: Scalars['Int']['output'];
};

export type PaginatedResultMetadata = {
  __typename?: 'PaginatedResultMetadata';
  hasMore: Scalars['Boolean']['output'];
  nextCursor?: Maybe<PageCursor>;
};

export type PlayerInputInteractionInput = {
  input: Scalars['String']['input'];
  interactionId: Scalars['String']['input'];
  userEpisodeId: Scalars['ID']['input'];
};

export type PrePublishEpisodeVersionValidation = {
  __typename?: 'PrePublishEpisodeVersionValidation';
  field: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type PrePublishEpisodeVersionValidationError = {
  __typename?: 'PrePublishEpisodeVersionValidationError';
  code: PrePublishEpisodeVersionValidationErrorCode;
  message: Scalars['String']['output'];
};

export enum PrePublishEpisodeVersionValidationErrorCode {
  InvalidInput = 'INVALID_INPUT',
  Unauthorized = 'UNAUTHORIZED',
  Unknown = 'UNKNOWN'
}

export type PrePublishEpisodeVersionValidationInput = {
  episodeId: Scalars['ID']['input'];
};

export type PrePublishEpisodeVersionValidationResponse = {
  __typename?: 'PrePublishEpisodeVersionValidationResponse';
  episodeValidations?: Maybe<Array<PrePublishEpisodeVersionValidation>>;
  error?: Maybe<Array<PrePublishEpisodeVersionValidationError>>;
  seriesValidations?: Maybe<Array<PrePublishEpisodeVersionValidation>>;
  status?: Maybe<PrePublishEpisodeVersionValidationStatus>;
};

export enum PrePublishEpisodeVersionValidationStatus {
  Invalid = 'INVALID',
  Valid = 'VALID'
}

export type Profile = {
  __typename?: 'Profile';
  email?: Maybe<Scalars['String']['output']>;
  initials?: Maybe<Scalars['String']['output']>;
  memberSince: Scalars['String']['output'];
};

export type ProfileQueryError = DisplayableError & {
  __typename?: 'ProfileQueryError';
  code: ProfileQueryErrorCode;
  message: Scalars['String']['output'];
  source: Scalars['String']['output'];
};

export enum ProfileQueryErrorCode {
  Unknown = 'UNKNOWN'
}

export type ProfileResponse = {
  __typename?: 'ProfileResponse';
  error?: Maybe<Array<ProfileQueryError>>;
  profile?: Maybe<Profile>;
};

export type ProviderMetadata = {
  deviceType: DeviceType;
  /** Autentication nonce provided by the provider; only required for Apple */
  nonce?: InputMaybe<Scalars['String']['input']>;
};

export type PublishEpisodeError = {
  __typename?: 'PublishEpisodeError';
  code: PublishEpisodeErrorCode;
  message: Scalars['String']['output'];
};

export enum PublishEpisodeErrorCode {
  EpisodeFlowMissingContent = 'EPISODE_FLOW_MISSING_CONTENT',
  InvalidInput = 'INVALID_INPUT',
  Unauthorized = 'UNAUTHORIZED',
  Unknown = 'UNKNOWN'
}

export type PublishEpisodeInput = {
  episodeId: Scalars['ID']['input'];
};

export type PublishEpisodeResponse = {
  __typename?: 'PublishEpisodeResponse';
  error?: Maybe<Array<PublishEpisodeError>>;
};

export type PublishedEpisodeVersion = {
  __typename?: 'PublishedEpisodeVersion';
  id: Scalars['ID']['output'];
  publishedAt: Scalars['DateTime']['output'];
  studioFlow: Scalars['JSON']['output'];
};

export type Query = {
  __typename?: 'Query';
  appUpdateData: AppUpdateData;
  characters: Array<Character>;
  createCharacterForm: CreateCharacterForm;
  createSeriesSatelliteData: CreateSeriesSatelliteDataResponse;
  creditActivity: Array<CreditActivity>;
  /** No need to have arguments or different type */
  credits: Scalars['Int']['output'];
  discovery: DiscoveryResponse;
  episodeGenerationStatus: EpisodeGenerationStatusResponse;
  episodeTemplate: EpisodeTemplateResponse;
  episodes: EpisodesResponse;
  featureFlags: GetFeatureFlagsResponse;
  inventory: InventoryResponse;
  inventoryItem: InventoryItemResponse;
  maestroEpisodes: MaestroEpisodesResponse;
  maestroSeries: MaestroSeriesResponse;
  myStories: MyStoriesQueryResponse;
  notificationPreferences: NotificationPreferencesResponse;
  prePublishEpisodeVersionValidation: PrePublishEpisodeVersionValidationResponse;
  /** @deprecated No longer called by Enigma client */
  profile: ProfileResponse;
  recentEpisodes: RecentEpisodesResponse;
  series: SeriesResponse;
  seriesGenres: SeriesGenresResponse;
  storyCategories: StoryCategoriesQueryResponse;
  storyFactories: Array<StoryFactory>;
  storyFactory?: Maybe<StoryFactory>;
  storyFactoryBranch?: Maybe<StoryFactoryBranch>;
  storyFactoryBranches: Array<StoryFactoryBranch>;
  studioEpisode: StudioEpisodeQueryResponse;
  template: Template;
  templates: Array<Template>;
  userEpisode: UserEpisodeResponse;
  userEpisodeStats: UserEpisodeStatsResponse;
  userSession: UserSessionResponse;
  waitForTransactionReceipt: WaitForTransactionReceiptResponse;
  world: WorldResponse;
  worlds: WorldsResponse;
};


export type QueryCreditActivityArgs = {
  input?: InputMaybe<CreditActivityInput>;
};


export type QueryDiscoveryArgs = {
  input?: InputMaybe<DiscoveryInput>;
};


export type QueryEpisodeGenerationStatusArgs = {
  input: EpisodeGenerationStatusInput;
};


export type QueryEpisodesArgs = {
  input?: InputMaybe<EpisodesQueryInput>;
};


export type QueryFeatureFlagsArgs = {
  input: GetFeatureFlagsInput;
};


export type QueryInventoryArgs = {
  characterId?: InputMaybe<Scalars['ID']['input']>;
  input?: InputMaybe<InventoryQueryInput>;
};


export type QueryInventoryItemArgs = {
  input: InventoryItemQueryInput;
};


export type QueryMaestroEpisodesArgs = {
  input: MaestroEpisodesQueryInput;
};


export type QueryMaestroSeriesArgs = {
  input: MaestroSeriesQueryInput;
};


export type QueryPrePublishEpisodeVersionValidationArgs = {
  input: PrePublishEpisodeVersionValidationInput;
};


export type QueryRecentEpisodesArgs = {
  input?: InputMaybe<RecentEpisodesInput>;
};


export type QuerySeriesArgs = {
  input?: InputMaybe<SeriesQueryInput>;
};


export type QueryStoryFactoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryStoryFactoryBranchArgs = {
  id: Scalars['ID']['input'];
};


export type QueryStoryFactoryBranchesArgs = {
  filter?: InputMaybe<StoryFactoryBranchFilter>;
};


export type QueryStudioEpisodeArgs = {
  input: StudioEpisodeQueryInput;
};


export type QueryTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTemplatesArgs = {
  input?: InputMaybe<TemplatesQueryInput>;
};


export type QueryUserEpisodeArgs = {
  episodeId?: InputMaybe<Scalars['ID']['input']>;
  episodePrice?: InputMaybe<Scalars['Int']['input']>;
  useDraft?: InputMaybe<Scalars['Boolean']['input']>;
  useNewVersion?: InputMaybe<Scalars['Boolean']['input']>;
  userEpisodeId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryWaitForTransactionReceiptArgs = {
  txHash: Scalars['String']['input'];
};


export type QueryWorldArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWorldsArgs = {
  input?: InputMaybe<WorldsInput>;
};

export type RealtimeToken = {
  __typename?: 'RealtimeToken';
  capability: Scalars['String']['output'];
  expires: Scalars['Float']['output'];
  issued: Scalars['Float']['output'];
  token: Scalars['String']['output'];
};

export type RealtimeTokenError = DisplayableError & {
  __typename?: 'RealtimeTokenError';
  code: RealtimeTokenErrorCode;
  message: Scalars['String']['output'];
  source: Scalars['String']['output'];
};

export enum RealtimeTokenErrorCode {
  ProviderError = 'PROVIDER_ERROR',
  Unknown = 'UNKNOWN'
}

export type RealtimeTokenResponse = {
  __typename?: 'RealtimeTokenResponse';
  error?: Maybe<Array<RealtimeTokenError>>;
  token?: Maybe<RealtimeToken>;
};

export type RecentEpisode = {
  __typename?: 'RecentEpisode';
  completionPercentage: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  episodeId: Scalars['ID']['output'];
  /** Indicates if the episode has a draft version */
  hasDraft: Scalars['Boolean']['output'];
  /** Indicates if the episode has a new version */
  hasNewVersion: Scalars['Boolean']['output'];
  imageUrl: Scalars['String']['output'];
  /** Indicates if the episode is generated */
  isGenerated: Scalars['Boolean']['output'];
  seriesId: Scalars['ID']['output'];
  seriesTitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
  userEpisodeId: Scalars['ID']['output'];
};

export enum RecentEpisodesErrorCode {
  NotFound = 'NOT_FOUND'
}

export type RecentEpisodesInput = {
  filterCreatedByMe?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RecentEpisodesResponse = {
  __typename?: 'RecentEpisodesResponse';
  error?: Maybe<Array<RecentEpisodesErrorCode>>;
  recentEpisodes?: Maybe<Array<RecentEpisode>>;
};

export type RpgConfigResponse = {
  __typename?: 'RpgConfigResponse';
  error?: Maybe<Array<SeriesQueryError>>;
};

export type Series = {
  __typename?: 'Series';
  /** the name of the author of the series */
  authorName?: Maybe<Scalars['String']['output']>;
  /**
   * if the series is created by the world creator
   * @deprecated Will be deleted soon
   */
  createdByWorldCreator: Scalars['Boolean']['output'];
  /** the current episode in the series */
  currentEpisode?: Maybe<Episode>;
  /** the description of the series */
  description: Scalars['String']['output'];
  /** the number of episodes in the series */
  episodeCount: Scalars['Int']['output'];
  /** the genre of the series */
  genre: Scalars['String']['output'];
  /** indicates if the series has a new episode */
  hasNewEpisode: Scalars['Boolean']['output'];
  /** unique identifier */
  id: Scalars['ID']['output'];
  /** Whether the user is liking the series or not */
  isLiked?: Maybe<Scalars['Boolean']['output']>;
  /** Number of likes this series has */
  likesCount: Scalars['Int']['output'];
  /** media assets for the series marketing materials */
  media: SeriesMedia;
  /**
   * total time to play all episodes in the series
   * @deprecated Will be deleted soon
   */
  playbackTimeInSeconds: Scalars['Int']['output'];
  /** Number of plays this series has with all its episodes */
  playsCount: Scalars['Int']['output'];
  /**
   * the date and time the series will be launched with timezone
   * @deprecated Will be deleted soon
   */
  releaseDateTime: Scalars['DateTime']['output'];
  /**
   * the availability status of the series
   * @deprecated The notion of a Series status has been removed. View an Episodes publish status for information.
   */
  status: SeriesStatus;
  /** title of the series */
  title: Scalars['String']['output'];
};

export enum SeriesErrorCode {
  InvalidInput = 'INVALID_INPUT',
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED',
  Unknown = 'UNKNOWN'
}

export type SeriesGenre = {
  __typename?: 'SeriesGenre';
  /** Unique Series Genre ID */
  id: Scalars['ID']['output'];
  /** Name of the Series Genre */
  name: Scalars['String']['output'];
  /** Count of all series that are a part of this genre */
  seriesCount: Scalars['Int']['output'];
};

export enum SeriesGenresQueryErrorCode {
  Unknown = 'UNKNOWN'
}

export type SeriesGenresResponse = {
  __typename?: 'SeriesGenresResponse';
  error?: Maybe<Array<SeriesGenresQueryErrorCode>>;
  genres?: Maybe<Array<SeriesGenre>>;
};

export type SeriesMedia = {
  __typename?: 'SeriesMedia';
  main: Scalars['String']['output'];
  thumbnail: Scalars['String']['output'];
};

export type SeriesQueryError = DisplayableError & {
  __typename?: 'SeriesQueryError';
  code: SeriesErrorCode;
  message: Scalars['String']['output'];
  source: Scalars['String']['output'];
};

export type SeriesQueryInput = {
  /** Boolean flag to filter created by the user */
  filterCreatedByMe?: InputMaybe<Scalars['Boolean']['input']>;
  /** Genre ID to filter results by */
  genreId?: InputMaybe<Scalars['ID']['input']>;
  /** Include drafts */
  includeUnpublished?: InputMaybe<Scalars['Boolean']['input']>;
  /** Series ID to filter results by */
  seriesId?: InputMaybe<Scalars['ID']['input']>;
  /** Indicate how to sort the list */
  sortBy?: InputMaybe<SeriesSort>;
  /** World ID to filter results by */
  worldId?: InputMaybe<Scalars['ID']['input']>;
};

export type SeriesResponse = {
  __typename?: 'SeriesResponse';
  error?: Maybe<Array<SeriesQueryError>>;
  series: Array<Series>;
};

export enum SeriesSort {
  Alphabetically = 'ALPHABETICALLY',
  MostLiked = 'MOST_LIKED',
  MostPlayed = 'MOST_PLAYED',
  RecentlyUpdated = 'RECENTLY_UPDATED'
}

export enum SeriesStatus {
  Published = 'PUBLISHED'
}

export type SetMessageViewedError = {
  __typename?: 'SetMessageViewedError';
  code: SetMessageViewedErrorCode;
  message: Scalars['String']['output'];
};

export enum SetMessageViewedErrorCode {
  MessageNotFound = 'MESSAGE_NOT_FOUND'
}

export type SetMessageViewedInput = {
  messageId: Scalars['ID']['input'];
};

export type SetMessageViewedResponse = {
  __typename?: 'SetMessageViewedResponse';
  error?: Maybe<Array<SetMessageViewedError>>;
};

export type SingleSelectInteractionInput = {
  interactionId: Scalars['String']['input'];
  optionId: Scalars['String']['input'];
  userEpisodeId: Scalars['ID']['input'];
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum StorageObjectType {
  Character = 'CHARACTER'
}

export type Story = {
  __typename?: 'Story';
  characters: Array<StoryCharacter>;
  coverImage: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  seriesId: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  userEpisodeId: Scalars['ID']['output'];
};

export type StoryCategoriesQueryResponse = {
  __typename?: 'StoryCategoriesQueryResponse';
  categories: Array<StoryCategory>;
};

export type StoryCategory = {
  __typename?: 'StoryCategory';
  iconUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type StoryCharacter = {
  __typename?: 'StoryCharacter';
  avatarImageUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type StoryFactory = {
  __typename?: 'StoryFactory';
  factoryStrategy: Scalars['String']['output'];
  fields: Array<FieldDefinition>;
  id: Scalars['ID']['output'];
  prompt: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type StoryFactoryBranch = {
  __typename?: 'StoryFactoryBranch';
  coverImage: Scalars['String']['output'];
  fields: Array<FieldValue>;
  id: Scalars['ID']['output'];
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  storyFactory: StoryFactory;
  title: Scalars['String']['output'];
};

export type StoryFactoryBranchFilter = {
  onlyPublished?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StudioEpisode = {
  __typename?: 'StudioEpisode';
  /** episode description */
  description: Scalars['String']['output'];
  /** draft details */
  draftVersion: DraftEpisodeVersion;
  /** unique identifier */
  id: Scalars['ID']['output'];
  /** media assets for the series marketing materials */
  media: EpisodeMedia;
  /** indicates the order of the episode */
  order?: Maybe<Scalars['Int']['output']>;
  /** numeric count of user episodes */
  playsCount: Scalars['Int']['output'];
  /** published details */
  publishedVersion?: Maybe<PublishedEpisodeVersion>;
  /** Reference */
  ref?: Maybe<Scalars['String']['output']>;
  /** series details */
  series: StudioEpisodeSeriesDetails;
  /** episode title */
  title: Scalars['String']['output'];
};

export type StudioEpisodeQueryError = {
  __typename?: 'StudioEpisodeQueryError';
  code: StudioEpisodeQueryErrorCode;
  message: Scalars['String']['output'];
};

export enum StudioEpisodeQueryErrorCode {
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED',
  Unknown = 'UNKNOWN'
}

export type StudioEpisodeQueryInput = {
  episodeId: Scalars['ID']['input'];
};

export type StudioEpisodeQueryResponse = {
  __typename?: 'StudioEpisodeQueryResponse';
  data?: Maybe<StudioEpisode>;
  error?: Maybe<Array<StudioEpisodeQueryError>>;
};

export type StudioEpisodeSeriesDetails = {
  __typename?: 'StudioEpisodeSeriesDetails';
  authorId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  rpgConfig?: Maybe<Scalars['JSON']['output']>;
  title: Scalars['String']['output'];
};

export type Template = {
  __typename?: 'Template';
  form: EpisodeTemplateForm;
  id: Scalars['ID']['output'];
  imageUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type TemplatesQueryInput = {
  sortBy?: InputMaybe<TemplatesQuerySortBy>;
  storyCategoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export enum TemplatesQuerySortBy {
  Alphabetical = 'ALPHABETICAL',
  New = 'NEW',
  Popular = 'POPULAR'
}

export type TransactionReceipt = {
  __typename?: 'TransactionReceipt';
  blockHash: Scalars['String']['output'];
  contractAddress?: Maybe<Scalars['String']['output']>;
  from: Scalars['String']['output'];
  status: TransactionStatus;
  to?: Maybe<Scalars['String']['output']>;
  transactionHash: Scalars['String']['output'];
};

export enum TransactionStatus {
  Reverted = 'REVERTED',
  Success = 'SUCCESS'
}

export type TriggerItemActionInput = {
  /** @deprecated Use itemId instead */
  characterItemId?: InputMaybe<Scalars['ID']['input']>;
  itemId?: InputMaybe<Scalars['ID']['input']>;
  userEpisodeId: Scalars['ID']['input'];
};

export type UpdateDiscoverySectionInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  onlyForKids?: InputMaybe<Scalars['Boolean']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  /** @deprecated Use updateSectionSeries instead */
  series?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type UpdateDiscoverySectionResponse = {
  __typename?: 'UpdateDiscoverySectionResponse';
  error?: Maybe<Array<DiscoveryError>>;
};

export type UpdateEpisodeError = {
  __typename?: 'UpdateEpisodeError';
  code: UpdateEpisodeErrorCode;
  message: Scalars['String']['output'];
};

export enum UpdateEpisodeErrorCode {
  InvalidInput = 'INVALID_INPUT',
  Unauthorized = 'UNAUTHORIZED',
  Unknown = 'UNKNOWN'
}

export type UpdateEpisodeInput = {
  coverImageUrl?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEpisodeResponse = {
  __typename?: 'UpdateEpisodeResponse';
  episode?: Maybe<UpdatedEpisodeDetails>;
  error?: Maybe<Array<UpdateEpisodeError>>;
};

export type UpdateFactoryBranchError = {
  __typename?: 'UpdateFactoryBranchError';
  code: UpdateFactoryBranchErrorCode;
  message: Scalars['String']['output'];
};

export enum UpdateFactoryBranchErrorCode {
  InvalidInput = 'INVALID_INPUT',
  NotFound = 'NOT_FOUND',
  Unknown = 'UNKNOWN'
}

export type UpdateFactoryBranchInput = {
  coverImageUrl?: InputMaybe<Scalars['String']['input']>;
  fields: Array<FieldValueInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFactoryBranchResponse = {
  __typename?: 'UpdateFactoryBranchResponse';
  error?: Maybe<Array<UpdateFactoryBranchError>>;
};

export type UpdateFactoryError = {
  __typename?: 'UpdateFactoryError';
  code: UpdateFactoryErrorCode;
  message: Scalars['String']['output'];
};

export enum UpdateFactoryErrorCode {
  InvalidInput = 'INVALID_INPUT',
  NotFound = 'NOT_FOUND',
  Unknown = 'UNKNOWN'
}

export type UpdateFactoryInput = {
  factoryStrategy?: InputMaybe<Scalars['String']['input']>;
  fields?: InputMaybe<Array<FieldDefinitionInput>>;
  prompt?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFactoryResponse = {
  __typename?: 'UpdateFactoryResponse';
  error?: Maybe<Array<UpdateFactoryError>>;
};

export type UpdateNotificationPreferenceInput = {
  id: Scalars['ID']['input'];
  isEnabled: Scalars['Boolean']['input'];
};

export type UpdateNotificationPreferencesInput = {
  preferences: Array<UpdateNotificationPreferenceInput>;
};

export type UpdateNotificationPreferencesResponse = {
  __typename?: 'UpdateNotificationPreferencesResponse';
  error?: Maybe<Array<NotificationPreferencesError>>;
  preferences: Array<NotificationPreference>;
};

export type UpdateSeriesError = {
  __typename?: 'UpdateSeriesError';
  code: UpdateSeriesErrorCode;
  message: Scalars['String']['output'];
};

export enum UpdateSeriesErrorCode {
  InvalidInput = 'INVALID_INPUT',
  Unauthorized = 'UNAUTHORIZED',
  Unknown = 'UNKNOWN'
}

export type UpdateSeriesInput = {
  coverImageUrl?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  genreId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  /** @deprecated Use `description` instead */
  overview?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  worldId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateSeriesResponse = {
  __typename?: 'UpdateSeriesResponse';
  error?: Maybe<Array<UpdateSeriesError>>;
  series?: Maybe<Series>;
};

export type UpdateSeriesRpgConfigInput = {
  rpgConfig: Scalars['JSON']['input'];
  seriesId: Scalars['ID']['input'];
};

export enum UpdateUserErrorCode {
  NotFound = 'NOT_FOUND',
  Unknown = 'UNKNOWN'
}

export type UpdateUserInput = {
  showOnlyKidsContent?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateUserResponse = {
  __typename?: 'UpdateUserResponse';
  error?: Maybe<Array<UpdateUserErrorCode>>;
  user?: Maybe<User>;
};

export type UpdatedEpisodeDetails = {
  __typename?: 'UpdatedEpisodeDetails';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  media: EpisodeMedia;
  title: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  authProviders: Array<AuthenticationProvider>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** @deprecated Onboarding is removed from the app */
  onboarded: Scalars['Boolean']['output'];
  showOnlyKidsContent?: Maybe<Scalars['Boolean']['output']>;
  walletAddress?: Maybe<Scalars['String']['output']>;
};

export type UserEpisode = {
  __typename?: 'UserEpisode';
  /** Will be used to subscribe to the channel */
  channelId: Scalars['String']['output'];
  /**
   * character details
   * @deprecated not needed
   */
  character: Character;
  /** Indicates the percentage value of the played time of the episode */
  completionPercentage: Scalars['Int']['output'];
  /** @deprecated not needed */
  episode: Episode;
  /** The Episode ID */
  episodeId: Scalars['ID']['output'];
  /** Episode HUD to display in the game session, like health, energy, etc. */
  hud: Array<UserEpisodeHudItem>;
  /** Game Session ID */
  id: Scalars['ID']['output'];
  /** Indicate if this UserEpisode is a draft */
  isDraft: Scalars['Boolean']['output'];
  /** All messages that were previously sent on this game session */
  messages: Array<UserEpisodeMessage>;
  /** List of images to be prefetched for the game session */
  prefetchImageList: Array<Scalars['String']['output']>;
  /** Current status of the game session */
  status: UserEpisodeStatus;
};

export type UserEpisodeCharacterCreatedMessage = {
  __typename?: 'UserEpisodeCharacterCreatedMessage';
  /** @deprecated Character attributes have been moved to the series level */
  attributes: Array<CharacterAttributeValue>;
  characterAvatarUrl?: Maybe<Scalars['String']['output']>;
  characterName: Scalars['String']['output'];
  /** @deprecated Character attributes have been moved to the series level */
  mainAttributeRef: Scalars['String']['output'];
};

export type UserEpisodeCharacterCreatorCompleteMessage = {
  __typename?: 'UserEpisodeCharacterCreatorCompleteMessage';
  content: Scalars['String']['output'];
};

export type UserEpisodeCharacterMainAttributeMessage = {
  __typename?: 'UserEpisodeCharacterMainAttributeMessage';
  /** @deprecated Character attributes have been moved to the series level */
  attributes: Array<CharacterAttribute>;
};

export type UserEpisodeCharacterRollAttributesMessage = {
  __typename?: 'UserEpisodeCharacterRollAttributesMessage';
  /** @deprecated Character attributes have been moved to the series level */
  mainAttribute: CharacterAttributeValue;
  /** @deprecated Character attributes have been moved to the series level */
  rolledAttributes: Array<CharacterAttributeValue>;
};

export type UserEpisodeCoinTossInteractionMessage = {
  __typename?: 'UserEpisodeCoinTossInteractionMessage';
  /** Attribute used to determine the outcome of the coin toss. */
  attribute: CharacterAttribute;
  id: Scalars['String']['output'];
  /** The odds for the coin toss to be successful. 1%-100% */
  successRate: Scalars['Int']['output'];
  /** Populated if there were a try on this coin toss */
  tossResult?: Maybe<CoinTossResult>;
};

export type UserEpisodeDetails = {
  __typename?: 'UserEpisodeDetails';
  completionPercentage: Scalars['Int']['output'];
  episodeId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
};

export type UserEpisodeDiceRollInteractionMessage = {
  __typename?: 'UserEpisodeDiceRollInteractionMessage';
  classDifficulty?: Maybe<Scalars['Int']['output']>;
  dices: Array<Dice>;
  id: Scalars['String']['output'];
  modifier: Scalars['Int']['output'];
  /** Populated when dice is rolled. */
  rollResult?: Maybe<Array<DiceRollResult>>;
};

export type UserEpisodeEndOfEpisodeMessage = {
  __typename?: 'UserEpisodeEndOfEpisodeMessage';
  action?: Maybe<EndOfEpisodeAction>;
  message?: Maybe<Scalars['String']['output']>;
};

export type UserEpisodeHudItem = {
  __typename?: 'UserEpisodeHudItem';
  iconUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  maxValue?: Maybe<Scalars['Int']['output']>;
  value: Scalars['Int']['output'];
};

export type UserEpisodeImageMessage = {
  __typename?: 'UserEpisodeImageMessage';
  alt: Scalars['String']['output'];
  height?: Maybe<Scalars['Int']['output']>;
  imageUrl: Scalars['String']['output'];
  width?: Maybe<Scalars['Int']['output']>;
};

export type UserEpisodeInteraction = {
  __typename?: 'UserEpisodeInteraction';
  action: Scalars['String']['output'];
};

export type UserEpisodeInventoryItemMessage = {
  __typename?: 'UserEpisodeInventoryItemMessage';
  delta: Scalars['Int']['output'];
  /** @deprecated Fetched client side */
  inventoryItem?: Maybe<InventoryItem>;
  itemDescription: Scalars['String']['output'];
  itemId: Scalars['String']['output'];
  itemImage: Scalars['String']['output'];
  itemName: Scalars['String']['output'];
};

export type UserEpisodeLocationMessage = {
  __typename?: 'UserEpisodeLocationMessage';
  location: Scalars['String']['output'];
  ref: Scalars['String']['output'];
};

export type UserEpisodeMessage = {
  __typename?: 'UserEpisodeMessage';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  payload?: Maybe<UserEpisodeMessagePayload>;
  type: UserEpisodeMessageType;
  viewed?: Maybe<Scalars['Boolean']['output']>;
};

export type UserEpisodeMessagePayload = UserEpisodeCharacterCreatedMessage | UserEpisodeCharacterCreatorCompleteMessage | UserEpisodeCharacterMainAttributeMessage | UserEpisodeCharacterRollAttributesMessage | UserEpisodeCoinTossInteractionMessage | UserEpisodeDiceRollInteractionMessage | UserEpisodeEndOfEpisodeMessage | UserEpisodeImageMessage | UserEpisodeInventoryItemMessage | UserEpisodeLocationMessage | UserEpisodeObjectiveCompletedMessage | UserEpisodeOnboardingCompleteMessage | UserEpisodePlayerInputMessage | UserEpisodePlayerInputResultMessage | UserEpisodeSelectedInteractionMessage | UserEpisodeSingleSelectInteractionMessage | UserEpisodeStatBlockMessage | UserEpisodeTextMessage;

export enum UserEpisodeMessageType {
  /** @deprecated Delete it */
  CharacterCreated = 'CHARACTER_CREATED',
  /** @deprecated Delete it */
  CharacterCreatorComplete = 'CHARACTER_CREATOR_COMPLETE',
  /** @deprecated Delete it */
  CharacterImageUpload = 'CHARACTER_IMAGE_UPLOAD',
  /** @deprecated Delete it */
  CharacterMainAttribute = 'CHARACTER_MAIN_ATTRIBUTE',
  /** @deprecated Delete it */
  CharacterNameInput = 'CHARACTER_NAME_INPUT',
  /** @deprecated Delete it */
  CharacterRollAttribute = 'CHARACTER_ROLL_ATTRIBUTE',
  CoinTossInteraction = 'COIN_TOSS_INTERACTION',
  DiceRoll = 'DICE_ROLL',
  EndOfEpisode = 'END_OF_EPISODE',
  Image = 'IMAGE',
  InventoryItem = 'INVENTORY_ITEM',
  /** @deprecated Delete it */
  Location = 'LOCATION',
  /** @deprecated Replaced by END_OF_EPISODE */
  ObjectiveCompleted = 'OBJECTIVE_COMPLETED',
  /** @deprecated Delete it */
  OnboardingComplete = 'ONBOARDING_COMPLETE',
  PlayerInput = 'PLAYER_INPUT',
  PlayerInputResult = 'PLAYER_INPUT_RESULT',
  SelectedInteraction = 'SELECTED_INTERACTION',
  SingleSelectInteraction = 'SINGLE_SELECT_INTERACTION',
  StatBlock = 'STAT_BLOCK',
  Text = 'TEXT',
  Unknown = 'UNKNOWN'
}

export type UserEpisodeMetrics = {
  __typename?: 'UserEpisodeMetrics';
  /** The number of play-throughs that a user has completed for this episode */
  completedEpisodeCount: Scalars['Int']['output'];
  /** Indicates the percentage value of the played time of the episode. */
  completionPercentage: Scalars['Int']['output'];
  /** Indicates if the episode is locked for the user */
  isLocked: Scalars['Boolean']['output'];
  /** Indicates if the episode requires the user to link auth before entering */
  requiresLinkedAuth: Scalars['Boolean']['output'];
  /** The user's latest episode ID - will only exist if user has started the episode */
  userEpisodeId?: Maybe<Scalars['ID']['output']>;
};

export type UserEpisodeObjective = {
  __typename?: 'UserEpisodeObjective';
  completionPercentage: Scalars['Int']['output'];
  hasCompleted: Scalars['Boolean']['output'];
  hidden: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  ref: Scalars['ID']['output'];
};

export type UserEpisodeObjectiveCompletedMessage = {
  __typename?: 'UserEpisodeObjectiveCompletedMessage';
  name: Scalars['String']['output'];
  ref: Scalars['String']['output'];
};

export type UserEpisodeOnboardingCompleteMessage = {
  __typename?: 'UserEpisodeOnboardingCompleteMessage';
  content: Scalars['String']['output'];
};

export type UserEpisodePlayerInputMessage = {
  __typename?: 'UserEpisodePlayerInputMessage';
  id: Scalars['String']['output'];
  maxLength?: Maybe<Scalars['Int']['output']>;
  prompt: Scalars['String']['output'];
};

export type UserEpisodePlayerInputResultMessage = {
  __typename?: 'UserEpisodePlayerInputResultMessage';
  id: Scalars['String']['output'];
  input: Scalars['String']['output'];
};

export type UserEpisodeResponse = {
  __typename?: 'UserEpisodeResponse';
  data?: Maybe<UserEpisode>;
  error?: Maybe<Array<GetUserEpisodeError>>;
};

export type UserEpisodeSelectedInteractionMessage = {
  __typename?: 'UserEpisodeSelectedInteractionMessage';
  id: Scalars['String']['output'];
  selectedOption: UserEpisodeSingleSelectOption;
};

export type UserEpisodeSingleSelectInteractionMessage = {
  __typename?: 'UserEpisodeSingleSelectInteractionMessage';
  id: Scalars['String']['output'];
  options: Array<UserEpisodeSingleSelectOption>;
  /** @deprecated No longer used */
  requireConfirmation?: Maybe<Scalars['Boolean']['output']>;
};

export type UserEpisodeSingleSelectOption = {
  __typename?: 'UserEpisodeSingleSelectOption';
  id: Scalars['String']['output'];
  payload?: Maybe<UserEpisodeSingleSelectOptionPayload>;
  text: Scalars['String']['output'];
  type?: Maybe<UserEpisodeSingleSelectOptionType>;
};

export type UserEpisodeSingleSelectOptionCoinToss = {
  __typename?: 'UserEpisodeSingleSelectOptionCoinToss';
  attribute?: Maybe<CharacterAttribute>;
};

export type UserEpisodeSingleSelectOptionPayload = UserEpisodeSingleSelectOptionCoinToss;

export enum UserEpisodeSingleSelectOptionType {
  CoinToss = 'COIN_TOSS',
  Default = 'DEFAULT',
  DiceRoll = 'DICE_ROLL'
}

export enum UserEpisodeStatBlockDirection {
  Column = 'COLUMN',
  Row = 'ROW'
}

export type UserEpisodeStatBlockField = {
  __typename?: 'UserEpisodeStatBlockField';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type UserEpisodeStatBlockImage = {
  __typename?: 'UserEpisodeStatBlockImage';
  height: Scalars['Int']['output'];
  /** @deprecated Use layout from statblock instead */
  size: UserEpisodeStatBlockImageSize;
  url: Scalars['String']['output'];
  width: Scalars['Int']['output'];
};

export enum UserEpisodeStatBlockImageSize {
  FullWidth = 'FULL_WIDTH',
  HalfWidth = 'HALF_WIDTH'
}

export enum UserEpisodeStatBlockLayout {
  Compact = 'COMPACT',
  FullWidth = 'FULL_WIDTH'
}

export type UserEpisodeStatBlockMessage = {
  __typename?: 'UserEpisodeStatBlockMessage';
  description: Scalars['String']['output'];
  image?: Maybe<UserEpisodeStatBlockImage>;
  layout?: Maybe<UserEpisodeStatBlockLayout>;
  name: Scalars['String']['output'];
  sections: Array<UserEpisodeStatBlockSection>;
  subtitle?: Maybe<Scalars['String']['output']>;
};

export type UserEpisodeStatBlockSection = {
  __typename?: 'UserEpisodeStatBlockSection';
  /** @deprecated Use layout instead */
  direction: UserEpisodeStatBlockDirection;
  fields: Array<UserEpisodeStatBlockField>;
  layout: UserEpisodeStatBlockSectionLayout;
  title?: Maybe<Scalars['String']['output']>;
};

export enum UserEpisodeStatBlockSectionLayout {
  /**
   * Displays a list of long descriptions.
   * Commonly used to describe abilities, traits, etc.
   */
  Description = 'DESCRIPTION',
  /**
   * Displays a list of items in a grid
   * Commonly used to show stats/attributes
   */
  GridList = 'GRID_LIST'
}

export type UserEpisodeStats = {
  __typename?: 'UserEpisodeStats';
  /** @deprecated No longer used to hydrate data */
  episode?: Maybe<Episode>;
  totalEpisodes: Scalars['Int']['output'];
  /** @deprecated No longer used to hydrate data */
  userEpisode?: Maybe<UserEpisodeDetails>;
};

export type UserEpisodeStatsError = {
  __typename?: 'UserEpisodeStatsError';
  code: UserEpisodeStatsErrorCode;
  message: Scalars['String']['output'];
};

export enum UserEpisodeStatsErrorCode {
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED'
}

export type UserEpisodeStatsResponse = {
  __typename?: 'UserEpisodeStatsResponse';
  data?: Maybe<UserEpisodeStats>;
  error?: Maybe<Array<UserEpisodeStatsError>>;
};

export enum UserEpisodeStatus {
  Ended = 'ENDED',
  InProgress = 'IN_PROGRESS'
}

export type UserEpisodeTextMessage = {
  __typename?: 'UserEpisodeTextMessage';
  character: GameCharacter;
  content: Scalars['String']['output'];
  highlighted?: Maybe<Scalars['Boolean']['output']>;
  partial?: Maybe<Scalars['Boolean']['output']>;
};

export enum UserSessionError {
  Unauthorized = 'UNAUTHORIZED',
  Unknown = 'UNKNOWN'
}

export type UserSessionResponse = {
  __typename?: 'UserSessionResponse';
  error?: Maybe<Array<UserSessionError>>;
  jwt?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type VerifyReceiptError = {
  __typename?: 'VerifyReceiptError';
  code: VerifyReceiptErrorCode;
  message: Scalars['String']['output'];
};

export enum VerifyReceiptErrorCode {
  InvalidReceipt = 'INVALID_RECEIPT',
  Unknown = 'UNKNOWN'
}

export type VerifyReceiptInput = {
  productId: Scalars['String']['input'];
  receiptData: Scalars['String']['input'];
};

export type VerifyReceiptResponse = {
  __typename?: 'VerifyReceiptResponse';
  credits: Scalars['Int']['output'];
  error?: Maybe<Array<VerifyReceiptError>>;
};

export type WaitForTransactionReceiptQueryError = DisplayableError & {
  __typename?: 'WaitForTransactionReceiptQueryError';
  code: WaitForTransactionReceiptQueryErrorCode;
  message: Scalars['String']['output'];
  source: Scalars['String']['output'];
};

export enum WaitForTransactionReceiptQueryErrorCode {
  InvalidHash = 'INVALID_HASH',
  NotFound = 'NOT_FOUND',
  Unknown = 'UNKNOWN'
}

export type WaitForTransactionReceiptResponse = {
  __typename?: 'WaitForTransactionReceiptResponse';
  error?: Maybe<Array<WaitForTransactionReceiptQueryError>>;
  txReceipt?: Maybe<TransactionReceipt>;
};

export type World = {
  __typename?: 'World';
  /** Unique World ID */
  id: Scalars['ID']['output'];
  /** URL of the World image */
  imageUrl: Scalars['String']['output'];
  /** Name of the World */
  name: Scalars['String']['output'];
  /** Count of all series that are a part of this world */
  seriesCount: Scalars['Int']['output'];
};

export enum WorldQueryErrorCode {
  NotFound = 'NOT_FOUND',
  Unknown = 'UNKNOWN'
}

export type WorldResponse = {
  __typename?: 'WorldResponse';
  error?: Maybe<Array<WorldQueryErrorCode>>;
  world?: Maybe<World>;
};

export type WorldsInput = {
  filterCreatedByMe?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export enum WorldsQueryErrorCode {
  Unknown = 'UNKNOWN'
}

export type WorldsResponse = {
  __typename?: 'WorldsResponse';
  error?: Maybe<Array<WorldsQueryErrorCode>>;
  worlds?: Maybe<Array<World>>;
};

export type DiscoverySectionSeriesFragment = { __typename?: 'DiscoverySectionSeries', onlyForKids: boolean, series: Array<{ __typename?: 'Series', id: string, title: string, media: { __typename?: 'SeriesMedia', main: string } }> };

export type DiscoverySectionFragment = { __typename?: 'DiscoverySection', id: string, name?: string | null | undefined, order: number, type: DiscoverySectionType, payload: { __typename?: 'DiscoverySectionBanner' } | { __typename?: 'DiscoverySectionCategories' } | { __typename?: 'DiscoverySectionGenres' } | { __typename?: 'DiscoverySectionHeroImage' } | { __typename?: 'DiscoverySectionRecentEpisodes' } | { __typename?: 'DiscoverySectionSeries', onlyForKids: boolean, series: Array<{ __typename?: 'Series', id: string, title: string, media: { __typename?: 'SeriesMedia', main: string } }> } | { __typename?: 'DiscoverySectionStoryTemplates' } };

export type EpisodeFragment = { __typename?: 'Episode', id: string, title: string, description: string, seriesId: string, ref?: string | null | undefined, hasDraft: boolean, hasNewVersion: boolean, media: { __typename?: 'EpisodeMedia', thumbnail: string, main: string } };

export type MaestroSeriesFragment = { __typename?: 'MaestroSeries', id: string, title?: string | null | undefined, description?: string | null | undefined, overview?: string | null | undefined, worldId?: string | null | undefined, genreId?: string | null | undefined, likesCount: number, playsCount: number, authorName?: string | null | undefined, media?: { __typename?: 'SeriesMedia', thumbnail: string, main: string } | null | undefined, world?: { __typename?: 'MaestroSeriesWorldDetails', id: string, title: string } | null | undefined, genre?: { __typename?: 'MaestroSeriesGenreDetails', id: string, title: string } | null | undefined };

export type SeriesFragment = { __typename?: 'Series', id: string, title: string, description: string, authorName?: string | null | undefined, genre: string, hasNewEpisode: boolean, releaseDateTime: string, playbackTimeInSeconds: number, status: SeriesStatus, episodeCount: number, createdByWorldCreator: boolean, media: { __typename?: 'SeriesMedia', thumbnail: string, main: string }, currentEpisode?: { __typename?: 'Episode', id: string, title: string, description: string, seriesId: string, ref?: string | null | undefined, hasDraft: boolean, hasNewVersion: boolean, media: { __typename?: 'EpisodeMedia', thumbnail: string, main: string } } | null | undefined };

export type SeriesShortFragment = { __typename?: 'Series', id: string, title: string, media: { __typename?: 'SeriesMedia', main: string } };

export type StoryFactoryBranchShortFragment = { __typename?: 'StoryFactoryBranch', id: string, title: string, coverImage: string, storyFactory: { __typename?: 'StoryFactory', title: string } };

export type StoryFactoryBranchFragment = { __typename?: 'StoryFactoryBranch', id: string, title: string, coverImage: string, publishedAt?: string | null | undefined, fields: Array<{ __typename?: 'FieldValue', fieldId: string, value: string }>, storyFactory: { __typename?: 'StoryFactory', id: string, title: string, prompt: string, fields: Array<{ __typename?: 'FieldDefinition', id: string, required: boolean, type: FieldType, name: string, options?: Array<string> | null | undefined, description?: string | null | undefined }> } };

export type StoryFactoryShortFragment = { __typename?: 'StoryFactory', id: string, title: string };

export type StoryFactoryFragment = { __typename?: 'StoryFactory', id: string, title: string, prompt: string, fields: Array<{ __typename?: 'FieldDefinition', id: string, required: boolean, type: FieldType, name: string, options?: Array<string> | null | undefined, description?: string | null | undefined }> };

export type StudioEpisodeMinifiedFragment = { __typename?: 'StudioEpisode', id: string, title: string, description: string, media: { __typename?: 'EpisodeMedia', thumbnail: string, main: string } };

export type StudioEpisodeFragment = { __typename?: 'StudioEpisode', id: string, title: string, description: string, ref?: string | null | undefined, order?: number | null | undefined, playsCount: number, media: { __typename?: 'EpisodeMedia', thumbnail: string, main: string }, series: { __typename?: 'StudioEpisodeSeriesDetails', id: string, title: string, rpgConfig?: Record<string, unknown> | null | undefined, authorId: string }, publishedVersion?: { __typename?: 'PublishedEpisodeVersion', id: string, publishedAt: string, studioFlow: Record<string, unknown> } | null | undefined, draftVersion: { __typename?: 'DraftEpisodeVersion', id: string, updatedAt?: string | null | undefined, studioFlow: Record<string, unknown>, createdAt: string } };

export type AuthenticateWithProviderMutationVariables = Exact<{
  input: AuthenticateWithProviderInput;
}>;


export type AuthenticateWithProviderMutation = { __typename?: 'Mutation', authenticateWithProvider: { __typename?: 'AuthenticateWithProviderResponse', jwt?: string | null | undefined, isNewlyLinked?: boolean | null | undefined, error?: Array<AuthenticationError> | null | undefined, user?: { __typename?: 'User', id: string, email?: string | null | undefined, walletAddress?: string | null | undefined, authProviders: Array<AuthenticationProvider> } | null | undefined } };

export type CreateAiEpisodeJobMutationVariables = Exact<{
  input: CreateAiEpisodeJobInput;
}>;


export type CreateAiEpisodeJobMutation = { __typename?: 'Mutation', createAiEpisodeJob: { __typename?: 'CreateAiEpisodeJobResponse', jobId: string, error?: Array<{ __typename?: 'CreateAiEpisodeJobError', code: CreateAiEpisodeJobErrorCode, message: string }> | null | undefined } };

export type CreateEpisodeMutationVariables = Exact<{
  input: CreateEpisodeInput;
}>;


export type CreateEpisodeMutation = { __typename?: 'Mutation', createEpisode: { __typename?: 'CreateEpisodeResponse', episode?: { __typename?: 'StudioEpisode', id: string, title: string, description: string, media: { __typename?: 'EpisodeMedia', thumbnail: string, main: string } } | null | undefined, error?: Array<{ __typename?: 'CreateEpisodeError', message: string, code: CreateEpisodeErrorCode }> | null | undefined } };

export type CreateSeriesMutationVariables = Exact<{
  input: CreateSeriesInput;
}>;


export type CreateSeriesMutation = { __typename?: 'Mutation', createSeries: { __typename?: 'CreateSeriesResponse', firstEpisodeId?: string | null | undefined, error?: Array<CreateSeriesErrorCode> | null | undefined, series?: { __typename?: 'Series', id: string, title: string, description: string, authorName?: string | null | undefined, genre: string, hasNewEpisode: boolean, releaseDateTime: string, playbackTimeInSeconds: number, status: SeriesStatus, episodeCount: number, createdByWorldCreator: boolean, media: { __typename?: 'SeriesMedia', thumbnail: string, main: string }, currentEpisode?: { __typename?: 'Episode', id: string, title: string, description: string, seriesId: string, ref?: string | null | undefined, hasDraft: boolean, hasNewVersion: boolean, media: { __typename?: 'EpisodeMedia', thumbnail: string, main: string } } | null | undefined } | null | undefined } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout?: void | null | undefined };

export type CreateDiscoverySectionSeriesMutationVariables = Exact<{
  input: CreateDiscoverySectionInput;
}>;


export type CreateDiscoverySectionSeriesMutation = { __typename?: 'Mutation', discoverySection: { __typename?: 'DiscoverySectionMutations', createSectionSeries: { __typename?: 'CreateDiscoverySectionResponse', section?: { __typename?: 'DiscoverySection', id: string, name?: string | null | undefined, order: number, type: DiscoverySectionType, payload: { __typename?: 'DiscoverySectionBanner' } | { __typename?: 'DiscoverySectionCategories' } | { __typename?: 'DiscoverySectionGenres' } | { __typename?: 'DiscoverySectionHeroImage' } | { __typename?: 'DiscoverySectionRecentEpisodes' } | { __typename?: 'DiscoverySectionSeries', onlyForKids: boolean, series: Array<{ __typename?: 'Series', id: string, title: string, media: { __typename?: 'SeriesMedia', main: string } }> } | { __typename?: 'DiscoverySectionStoryTemplates' } } | null | undefined, error?: Array<{ __typename?: 'DiscoveryError', message: string, code: DiscoveryErrorCode }> | null | undefined } } };

export type UpdateDiscoverySectionSeriesMutationVariables = Exact<{
  input: UpdateDiscoverySectionInput;
}>;


export type UpdateDiscoverySectionSeriesMutation = { __typename?: 'Mutation', discoverySection: { __typename?: 'DiscoverySectionMutations', updateSectionSeries: { __typename?: 'UpdateDiscoverySectionResponse', error?: Array<{ __typename?: 'DiscoveryError', message: string, code: DiscoveryErrorCode }> | null | undefined } } };

export type DeleteDiscoverySectionSeriesMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteDiscoverySectionSeriesMutation = { __typename?: 'Mutation', discoverySection: { __typename?: 'DiscoverySectionMutations', deleteSection: { __typename?: 'DeleteDiscoverySectionResponse', error?: Array<{ __typename?: 'DiscoveryError', message: string, code: DiscoveryErrorCode }> | null | undefined } } };

export type PublishEpisodeMutationVariables = Exact<{
  input: PublishEpisodeInput;
}>;


export type PublishEpisodeMutation = { __typename?: 'Mutation', publishEpisode: { __typename?: 'PublishEpisodeResponse', error?: Array<{ __typename?: 'PublishEpisodeError', code: PublishEpisodeErrorCode, message: string }> | null | undefined } };

export type RealtimeTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type RealtimeTokenMutation = { __typename?: 'Mutation', realtimeToken: { __typename?: 'RealtimeTokenResponse', token?: { __typename?: 'RealtimeToken', capability: string, expires: number, issued: number, token: string } | null | undefined, error?: Array<{ __typename?: 'RealtimeTokenError', message: string, code: RealtimeTokenErrorCode, source: string }> | null | undefined } };

export type UpdateStoryFactoryMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateFactoryInput;
}>;


export type UpdateStoryFactoryMutation = { __typename?: 'Mutation', updateFactory: { __typename?: 'UpdateFactoryResponse', error?: Array<{ __typename?: 'UpdateFactoryError', code: UpdateFactoryErrorCode, message: string }> | null | undefined } };

export type CreateStoryFactoryMutationVariables = Exact<{
  input: CreateFactoryInput;
}>;


export type CreateStoryFactoryMutation = { __typename?: 'Mutation', createFactory: { __typename?: 'CreateFactoryResponse', error?: Array<{ __typename?: 'CreateFactoryError', code: CreateFactoryErrorCode, message: string }> | null | undefined, storyFactory?: { __typename?: 'StoryFactory', id: string, title: string } | null | undefined } };

export type UpdateEpisodeMutationVariables = Exact<{
  input: UpdateEpisodeInput;
}>;


export type UpdateEpisodeMutation = { __typename?: 'Mutation', updateEpisode: { __typename?: 'UpdateEpisodeResponse', episode?: { __typename?: 'UpdatedEpisodeDetails', id: string, title: string, description: string, media: { __typename?: 'EpisodeMedia', thumbnail: string, main: string } } | null | undefined, error?: Array<{ __typename?: 'UpdateEpisodeError', code: UpdateEpisodeErrorCode, message: string }> | null | undefined } };

export type UpdateRpgConfigMutationVariables = Exact<{
  input: UpdateSeriesRpgConfigInput;
}>;


export type UpdateRpgConfigMutation = { __typename?: 'Mutation', updateSeriesRpgConfig: { __typename?: 'RpgConfigResponse', error?: Array<{ __typename?: 'SeriesQueryError', code: SeriesErrorCode, message: string }> | null | undefined } };

export type UpdateSeriesMutationVariables = Exact<{
  input: UpdateSeriesInput;
}>;


export type UpdateSeriesMutation = { __typename?: 'Mutation', updateSeries: { __typename?: 'UpdateSeriesResponse', series?: { __typename?: 'Series', id: string, title: string, description: string, authorName?: string | null | undefined, genre: string, hasNewEpisode: boolean, releaseDateTime: string, playbackTimeInSeconds: number, status: SeriesStatus, episodeCount: number, createdByWorldCreator: boolean, media: { __typename?: 'SeriesMedia', thumbnail: string, main: string }, currentEpisode?: { __typename?: 'Episode', id: string, title: string, description: string, seriesId: string, ref?: string | null | undefined, hasDraft: boolean, hasNewVersion: boolean, media: { __typename?: 'EpisodeMedia', thumbnail: string, main: string } } | null | undefined } | null | undefined, error?: Array<{ __typename?: 'UpdateSeriesError', code: UpdateSeriesErrorCode, message: string }> | null | undefined } };

export type CreateSeriesSatelliteDataQueryVariables = Exact<{ [key: string]: never; }>;


export type CreateSeriesSatelliteDataQuery = { __typename?: 'Query', createSeriesSatelliteData: { __typename?: 'CreateSeriesSatelliteDataResponse', genres?: Array<{ __typename?: 'CreateSeriesSatelliteDataOption', id: string, label: string }> | null | undefined, worlds?: Array<{ __typename?: 'CreateSeriesSatelliteDataOption', id: string, label: string }> | null | undefined } };

export type EpisodeGenerationStatusQueryVariables = Exact<{
  input: EpisodeGenerationStatusInput;
}>;


export type EpisodeGenerationStatusQuery = { __typename?: 'Query', episodeGenerationStatus: { __typename?: 'EpisodeGenerationStatusResponse', error?: Array<EpisodeGenerationStatusError> | null | undefined, job?: { __typename?: 'EpisodeGenerationJob', episodeId?: string | null | undefined, seriesId?: string | null | undefined, id: string, status: EpisodeGenerationStatus } | null | undefined } };

export type MaestroEpisodesQueryVariables = Exact<{
  input: MaestroEpisodesQueryInput;
}>;


export type MaestroEpisodesQuery = { __typename?: 'Query', maestroEpisodes: { __typename?: 'MaestroEpisodesResponse', episodes?: Array<{ __typename?: 'StudioEpisode', id: string, title: string, description: string, ref?: string | null | undefined, order?: number | null | undefined, playsCount: number, media: { __typename?: 'EpisodeMedia', thumbnail: string, main: string }, series: { __typename?: 'StudioEpisodeSeriesDetails', id: string, title: string, rpgConfig?: Record<string, unknown> | null | undefined, authorId: string }, publishedVersion?: { __typename?: 'PublishedEpisodeVersion', id: string, publishedAt: string, studioFlow: Record<string, unknown> } | null | undefined, draftVersion: { __typename?: 'DraftEpisodeVersion', id: string, updatedAt?: string | null | undefined, studioFlow: Record<string, unknown>, createdAt: string } }> | null | undefined, metadata?: { __typename?: 'PaginatedResultMetadata', hasMore: boolean, nextCursor?: { __typename?: 'PageCursor', offset: number, limit: number } | null | undefined } | null | undefined, error?: Array<{ __typename?: 'MaestroEpisodesQueryError', message: string, code: MaestroEpisodesErrorCode }> | null | undefined } };

export type MaestroSeriesQueryVariables = Exact<{
  input: MaestroSeriesQueryInput;
}>;


export type MaestroSeriesQuery = { __typename?: 'Query', maestroSeries: { __typename?: 'MaestroSeriesResponse', series?: { __typename?: 'MaestroSeries', id: string, title?: string | null | undefined, description?: string | null | undefined, overview?: string | null | undefined, worldId?: string | null | undefined, genreId?: string | null | undefined, likesCount: number, playsCount: number, authorName?: string | null | undefined, media?: { __typename?: 'SeriesMedia', thumbnail: string, main: string } | null | undefined, world?: { __typename?: 'MaestroSeriesWorldDetails', id: string, title: string } | null | undefined, genre?: { __typename?: 'MaestroSeriesGenreDetails', id: string, title: string } | null | undefined } | null | undefined, error?: Array<{ __typename?: 'MaestroSeriesQueryError', message: string, code: MaestroSeriesErrorCode }> | null | undefined } };

export type MaestroDiscoveryQueryVariables = Exact<{
  input?: InputMaybe<DiscoveryInput>;
  seriesInput?: InputMaybe<SeriesQueryInput>;
}>;


export type MaestroDiscoveryQuery = { __typename?: 'Query', series: { __typename?: 'SeriesResponse', series: Array<{ __typename?: 'Series', id: string, title: string, media: { __typename?: 'SeriesMedia', main: string } }> }, storyFactoryBranches: Array<{ __typename?: 'StoryFactoryBranch', id: string, title: string, coverImage: string, storyFactory: { __typename?: 'StoryFactory', title: string } }>, discovery: { __typename?: 'DiscoveryResponse', sections?: Array<{ __typename?: 'DiscoverySection', id: string, name?: string | null | undefined, order: number, type: DiscoverySectionType, payload: { __typename?: 'DiscoverySectionBanner' } | { __typename?: 'DiscoverySectionCategories' } | { __typename?: 'DiscoverySectionGenres' } | { __typename?: 'DiscoverySectionHeroImage' } | { __typename?: 'DiscoverySectionRecentEpisodes' } | { __typename?: 'DiscoverySectionSeries', onlyForKids: boolean, series: Array<{ __typename?: 'Series', id: string, title: string, media: { __typename?: 'SeriesMedia', main: string } }> } | { __typename?: 'DiscoverySectionStoryTemplates' } }> | null | undefined, error?: Array<{ __typename?: 'DiscoveryError', message: string, code: DiscoveryErrorCode }> | null | undefined } };

export type PrePublishEpisodeVersionValidationQueryVariables = Exact<{
  input: PrePublishEpisodeVersionValidationInput;
}>;


export type PrePublishEpisodeVersionValidationQuery = { __typename?: 'Query', prePublishEpisodeVersionValidation: { __typename?: 'PrePublishEpisodeVersionValidationResponse', status?: PrePublishEpisodeVersionValidationStatus | null | undefined, episodeValidations?: Array<{ __typename?: 'PrePublishEpisodeVersionValidation', field: string, message: string }> | null | undefined, seriesValidations?: Array<{ __typename?: 'PrePublishEpisodeVersionValidation', field: string, message: string }> | null | undefined, error?: Array<{ __typename?: 'PrePublishEpisodeVersionValidationError', message: string, code: PrePublishEpisodeVersionValidationErrorCode }> | null | undefined } };

export type ListStoryFactoryQueryVariables = Exact<{ [key: string]: never; }>;


export type ListStoryFactoryQuery = { __typename?: 'Query', storyFactories: Array<{ __typename?: 'StoryFactory', id: string, title: string }> };

export type ListStoryFactoryBranchQueryVariables = Exact<{ [key: string]: never; }>;


export type ListStoryFactoryBranchQuery = { __typename?: 'Query', storyFactoryBranches: Array<{ __typename?: 'StoryFactoryBranch', id: string, title: string, coverImage: string, storyFactory: { __typename?: 'StoryFactory', title: string } }> };

export type GetStoryFactoryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetStoryFactoryQuery = { __typename?: 'Query', storyFactory?: { __typename?: 'StoryFactory', id: string, title: string, prompt: string, fields: Array<{ __typename?: 'FieldDefinition', id: string, required: boolean, type: FieldType, name: string, options?: Array<string> | null | undefined, description?: string | null | undefined }> } | null | undefined };

export type GetStoryFactoryBranchQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetStoryFactoryBranchQuery = { __typename?: 'Query', storyFactoryBranch?: { __typename?: 'StoryFactoryBranch', id: string, title: string, coverImage: string, publishedAt?: string | null | undefined, fields: Array<{ __typename?: 'FieldValue', fieldId: string, value: string }>, storyFactory: { __typename?: 'StoryFactory', id: string, title: string, prompt: string, fields: Array<{ __typename?: 'FieldDefinition', id: string, required: boolean, type: FieldType, name: string, options?: Array<string> | null | undefined, description?: string | null | undefined }> } } | null | undefined };

export type StudioEpisodeQueryVariables = Exact<{
  input: StudioEpisodeQueryInput;
}>;


export type StudioEpisodeQuery = { __typename?: 'Query', studioEpisode: { __typename?: 'StudioEpisodeQueryResponse', data?: { __typename?: 'StudioEpisode', id: string, title: string, description: string, ref?: string | null | undefined, order?: number | null | undefined, playsCount: number, media: { __typename?: 'EpisodeMedia', thumbnail: string, main: string }, series: { __typename?: 'StudioEpisodeSeriesDetails', id: string, title: string, rpgConfig?: Record<string, unknown> | null | undefined, authorId: string }, publishedVersion?: { __typename?: 'PublishedEpisodeVersion', id: string, publishedAt: string, studioFlow: Record<string, unknown> } | null | undefined, draftVersion: { __typename?: 'DraftEpisodeVersion', id: string, updatedAt?: string | null | undefined, studioFlow: Record<string, unknown>, createdAt: string } } | null | undefined, error?: Array<{ __typename?: 'StudioEpisodeQueryError', message: string, code: StudioEpisodeQueryErrorCode }> | null | undefined } };

export type EpisodeStatusQueryVariables = Exact<{
  input: StudioEpisodeQueryInput;
}>;


export type EpisodeStatusQuery = { __typename?: 'Query', studioEpisode: { __typename?: 'StudioEpisodeQueryResponse', data?: { __typename?: 'StudioEpisode', id: string, order?: number | null | undefined, publishedVersion?: { __typename?: 'PublishedEpisodeVersion', publishedAt: string } | null | undefined, draftVersion: { __typename?: 'DraftEpisodeVersion', updatedAt?: string | null | undefined } } | null | undefined, error?: Array<{ __typename?: 'StudioEpisodeQueryError', message: string, code: StudioEpisodeQueryErrorCode }> | null | undefined } };

export const SeriesShortFragmentDoc = `
    fragment SeriesShort on Series {
  id
  title
  media {
    main
  }
}
    `;
export const DiscoverySectionSeriesFragmentDoc = `
    fragment DiscoverySectionSeries on DiscoverySectionSeries {
  series {
    ...SeriesShort
  }
  onlyForKids
}
    `;
export const DiscoverySectionFragmentDoc = `
    fragment DiscoverySection on DiscoverySection {
  id
  name
  order
  type
  payload {
    ... on DiscoverySectionSeries {
      ...DiscoverySectionSeries
    }
  }
}
    `;
export const MaestroSeriesFragmentDoc = `
    fragment MaestroSeries on MaestroSeries {
  id
  title
  description
  overview
  media {
    thumbnail
    main
  }
  worldId
  genreId
  world {
    id
    title
  }
  genre {
    id
    title
  }
  likesCount
  playsCount
  authorName
}
    `;
export const EpisodeFragmentDoc = `
    fragment Episode on Episode {
  id
  title
  description
  media {
    thumbnail
    main
  }
  seriesId
  ref
  hasDraft
  hasNewVersion
}
    `;
export const SeriesFragmentDoc = `
    fragment Series on Series {
  id
  title
  description
  authorName
  genre
  hasNewEpisode
  releaseDateTime
  media {
    thumbnail
    main
  }
  playbackTimeInSeconds
  status
  episodeCount
  currentEpisode {
    ...Episode
  }
  createdByWorldCreator
}
    `;
export const StoryFactoryBranchShortFragmentDoc = `
    fragment StoryFactoryBranchShort on StoryFactoryBranch {
  id
  title
  coverImage
  storyFactory {
    title
  }
}
    `;
export const StoryFactoryFragmentDoc = `
    fragment StoryFactory on StoryFactory {
  id
  title
  prompt
  fields {
    id
    required
    type
    name
    options
    description
  }
}
    `;
export const StoryFactoryBranchFragmentDoc = `
    fragment StoryFactoryBranch on StoryFactoryBranch {
  id
  title
  coverImage
  fields {
    fieldId
    value
  }
  publishedAt
  storyFactory {
    ...StoryFactory
  }
}
    `;
export const StoryFactoryShortFragmentDoc = `
    fragment StoryFactoryShort on StoryFactory {
  id
  title
}
    `;
export const StudioEpisodeMinifiedFragmentDoc = `
    fragment StudioEpisodeMinified on StudioEpisode {
  id
  title
  description
  media {
    thumbnail
    main
  }
}
    `;
export const StudioEpisodeFragmentDoc = `
    fragment StudioEpisode on StudioEpisode {
  id
  title
  description
  media {
    thumbnail
    main
  }
  ref
  series {
    id
    title
    rpgConfig
    authorId
  }
  publishedVersion {
    id
    publishedAt
    studioFlow
  }
  draftVersion {
    id
    updatedAt
    studioFlow
    createdAt
  }
  order
  playsCount
}
    `;
export const AuthenticateWithProviderDocument = `
    mutation authenticateWithProvider($input: AuthenticateWithProviderInput!) {
  authenticateWithProvider(input: $input) {
    jwt
    isNewlyLinked
    user {
      id
      email
      walletAddress
      authProviders
    }
    error
  }
}
    `;
export const useAuthenticateWithProviderMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AuthenticateWithProviderMutation, TError, AuthenticateWithProviderMutationVariables, TContext>) =>
    useMutation<AuthenticateWithProviderMutation, TError, AuthenticateWithProviderMutationVariables, TContext>(
      ['authenticateWithProvider'],
      (variables?: AuthenticateWithProviderMutationVariables) => fetcher<AuthenticateWithProviderMutation, AuthenticateWithProviderMutationVariables>(AuthenticateWithProviderDocument, variables)(),
      options
    );
export const CreateAiEpisodeJobDocument = `
    mutation createAiEpisodeJob($input: CreateAiEpisodeJobInput!) {
  createAiEpisodeJob(input: $input) {
    jobId
    error {
      code
      message
    }
  }
}
    `;
export const useCreateAiEpisodeJobMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateAiEpisodeJobMutation, TError, CreateAiEpisodeJobMutationVariables, TContext>) =>
    useMutation<CreateAiEpisodeJobMutation, TError, CreateAiEpisodeJobMutationVariables, TContext>(
      ['createAiEpisodeJob'],
      (variables?: CreateAiEpisodeJobMutationVariables) => fetcher<CreateAiEpisodeJobMutation, CreateAiEpisodeJobMutationVariables>(CreateAiEpisodeJobDocument, variables)(),
      options
    );
export const CreateEpisodeDocument = `
    mutation createEpisode($input: CreateEpisodeInput!) {
  createEpisode(input: $input) {
    episode {
      ...StudioEpisodeMinified
    }
    error {
      message
      code
    }
  }
}
    ${StudioEpisodeMinifiedFragmentDoc}`;
export const useCreateEpisodeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateEpisodeMutation, TError, CreateEpisodeMutationVariables, TContext>) =>
    useMutation<CreateEpisodeMutation, TError, CreateEpisodeMutationVariables, TContext>(
      ['createEpisode'],
      (variables?: CreateEpisodeMutationVariables) => fetcher<CreateEpisodeMutation, CreateEpisodeMutationVariables>(CreateEpisodeDocument, variables)(),
      options
    );
export const CreateSeriesDocument = `
    mutation createSeries($input: CreateSeriesInput!) {
  createSeries(input: $input) {
    series {
      ...Series
    }
    firstEpisodeId
    error
  }
}
    ${SeriesFragmentDoc}
${EpisodeFragmentDoc}`;
export const useCreateSeriesMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateSeriesMutation, TError, CreateSeriesMutationVariables, TContext>) =>
    useMutation<CreateSeriesMutation, TError, CreateSeriesMutationVariables, TContext>(
      ['createSeries'],
      (variables?: CreateSeriesMutationVariables) => fetcher<CreateSeriesMutation, CreateSeriesMutationVariables>(CreateSeriesDocument, variables)(),
      options
    );
export const LogoutDocument = `
    mutation logout {
  logout
}
    `;
export const useLogoutMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<LogoutMutation, TError, LogoutMutationVariables, TContext>) =>
    useMutation<LogoutMutation, TError, LogoutMutationVariables, TContext>(
      ['logout'],
      (variables?: LogoutMutationVariables) => fetcher<LogoutMutation, LogoutMutationVariables>(LogoutDocument, variables)(),
      options
    );
export const CreateDiscoverySectionSeriesDocument = `
    mutation createDiscoverySectionSeries($input: CreateDiscoverySectionInput!) {
  discoverySection {
    createSectionSeries(input: $input) {
      section {
        ...DiscoverySection
      }
      error {
        message
        code
      }
    }
  }
}
    ${DiscoverySectionFragmentDoc}
${DiscoverySectionSeriesFragmentDoc}
${SeriesShortFragmentDoc}`;
export const useCreateDiscoverySectionSeriesMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateDiscoverySectionSeriesMutation, TError, CreateDiscoverySectionSeriesMutationVariables, TContext>) =>
    useMutation<CreateDiscoverySectionSeriesMutation, TError, CreateDiscoverySectionSeriesMutationVariables, TContext>(
      ['createDiscoverySectionSeries'],
      (variables?: CreateDiscoverySectionSeriesMutationVariables) => fetcher<CreateDiscoverySectionSeriesMutation, CreateDiscoverySectionSeriesMutationVariables>(CreateDiscoverySectionSeriesDocument, variables)(),
      options
    );
export const UpdateDiscoverySectionSeriesDocument = `
    mutation updateDiscoverySectionSeries($input: UpdateDiscoverySectionInput!) {
  discoverySection {
    updateSectionSeries(input: $input) {
      error {
        message
        code
      }
    }
  }
}
    `;
export const useUpdateDiscoverySectionSeriesMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateDiscoverySectionSeriesMutation, TError, UpdateDiscoverySectionSeriesMutationVariables, TContext>) =>
    useMutation<UpdateDiscoverySectionSeriesMutation, TError, UpdateDiscoverySectionSeriesMutationVariables, TContext>(
      ['updateDiscoverySectionSeries'],
      (variables?: UpdateDiscoverySectionSeriesMutationVariables) => fetcher<UpdateDiscoverySectionSeriesMutation, UpdateDiscoverySectionSeriesMutationVariables>(UpdateDiscoverySectionSeriesDocument, variables)(),
      options
    );
export const DeleteDiscoverySectionSeriesDocument = `
    mutation deleteDiscoverySectionSeries($id: ID!) {
  discoverySection {
    deleteSection(id: $id) {
      error {
        message
        code
      }
    }
  }
}
    `;
export const useDeleteDiscoverySectionSeriesMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteDiscoverySectionSeriesMutation, TError, DeleteDiscoverySectionSeriesMutationVariables, TContext>) =>
    useMutation<DeleteDiscoverySectionSeriesMutation, TError, DeleteDiscoverySectionSeriesMutationVariables, TContext>(
      ['deleteDiscoverySectionSeries'],
      (variables?: DeleteDiscoverySectionSeriesMutationVariables) => fetcher<DeleteDiscoverySectionSeriesMutation, DeleteDiscoverySectionSeriesMutationVariables>(DeleteDiscoverySectionSeriesDocument, variables)(),
      options
    );
export const PublishEpisodeDocument = `
    mutation publishEpisode($input: PublishEpisodeInput!) {
  publishEpisode(input: $input) {
    error {
      code
      message
    }
  }
}
    `;
export const usePublishEpisodeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<PublishEpisodeMutation, TError, PublishEpisodeMutationVariables, TContext>) =>
    useMutation<PublishEpisodeMutation, TError, PublishEpisodeMutationVariables, TContext>(
      ['publishEpisode'],
      (variables?: PublishEpisodeMutationVariables) => fetcher<PublishEpisodeMutation, PublishEpisodeMutationVariables>(PublishEpisodeDocument, variables)(),
      options
    );
export const RealtimeTokenDocument = `
    mutation realtimeToken {
  realtimeToken {
    token {
      capability
      expires
      issued
      token
    }
    error {
      message
      code
      source
    }
  }
}
    `;
export const useRealtimeTokenMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RealtimeTokenMutation, TError, RealtimeTokenMutationVariables, TContext>) =>
    useMutation<RealtimeTokenMutation, TError, RealtimeTokenMutationVariables, TContext>(
      ['realtimeToken'],
      (variables?: RealtimeTokenMutationVariables) => fetcher<RealtimeTokenMutation, RealtimeTokenMutationVariables>(RealtimeTokenDocument, variables)(),
      options
    );
export const UpdateStoryFactoryDocument = `
    mutation updateStoryFactory($id: ID!, $input: UpdateFactoryInput!) {
  updateFactory(id: $id, input: $input) {
    error {
      code
      message
    }
  }
}
    `;
export const useUpdateStoryFactoryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateStoryFactoryMutation, TError, UpdateStoryFactoryMutationVariables, TContext>) =>
    useMutation<UpdateStoryFactoryMutation, TError, UpdateStoryFactoryMutationVariables, TContext>(
      ['updateStoryFactory'],
      (variables?: UpdateStoryFactoryMutationVariables) => fetcher<UpdateStoryFactoryMutation, UpdateStoryFactoryMutationVariables>(UpdateStoryFactoryDocument, variables)(),
      options
    );
export const CreateStoryFactoryDocument = `
    mutation createStoryFactory($input: CreateFactoryInput!) {
  createFactory(input: $input) {
    error {
      code
      message
    }
    storyFactory {
      ...StoryFactoryShort
    }
  }
}
    ${StoryFactoryShortFragmentDoc}`;
export const useCreateStoryFactoryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateStoryFactoryMutation, TError, CreateStoryFactoryMutationVariables, TContext>) =>
    useMutation<CreateStoryFactoryMutation, TError, CreateStoryFactoryMutationVariables, TContext>(
      ['createStoryFactory'],
      (variables?: CreateStoryFactoryMutationVariables) => fetcher<CreateStoryFactoryMutation, CreateStoryFactoryMutationVariables>(CreateStoryFactoryDocument, variables)(),
      options
    );
export const UpdateEpisodeDocument = `
    mutation updateEpisode($input: UpdateEpisodeInput!) {
  updateEpisode(input: $input) {
    episode {
      id
      title
      description
      media {
        thumbnail
        main
      }
    }
    error {
      code
      message
    }
  }
}
    `;
export const useUpdateEpisodeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateEpisodeMutation, TError, UpdateEpisodeMutationVariables, TContext>) =>
    useMutation<UpdateEpisodeMutation, TError, UpdateEpisodeMutationVariables, TContext>(
      ['updateEpisode'],
      (variables?: UpdateEpisodeMutationVariables) => fetcher<UpdateEpisodeMutation, UpdateEpisodeMutationVariables>(UpdateEpisodeDocument, variables)(),
      options
    );
export const UpdateRpgConfigDocument = `
    mutation updateRpgConfig($input: UpdateSeriesRpgConfigInput!) {
  updateSeriesRpgConfig(input: $input) {
    error {
      code
      message
    }
  }
}
    `;
export const useUpdateRpgConfigMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateRpgConfigMutation, TError, UpdateRpgConfigMutationVariables, TContext>) =>
    useMutation<UpdateRpgConfigMutation, TError, UpdateRpgConfigMutationVariables, TContext>(
      ['updateRpgConfig'],
      (variables?: UpdateRpgConfigMutationVariables) => fetcher<UpdateRpgConfigMutation, UpdateRpgConfigMutationVariables>(UpdateRpgConfigDocument, variables)(),
      options
    );
export const UpdateSeriesDocument = `
    mutation updateSeries($input: UpdateSeriesInput!) {
  updateSeries(input: $input) {
    series {
      ...Series
    }
    error {
      code
      message
    }
  }
}
    ${SeriesFragmentDoc}
${EpisodeFragmentDoc}`;
export const useUpdateSeriesMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateSeriesMutation, TError, UpdateSeriesMutationVariables, TContext>) =>
    useMutation<UpdateSeriesMutation, TError, UpdateSeriesMutationVariables, TContext>(
      ['updateSeries'],
      (variables?: UpdateSeriesMutationVariables) => fetcher<UpdateSeriesMutation, UpdateSeriesMutationVariables>(UpdateSeriesDocument, variables)(),
      options
    );
export const CreateSeriesSatelliteDataDocument = `
    query createSeriesSatelliteData {
  createSeriesSatelliteData {
    genres {
      id
      label
    }
    worlds {
      id
      label
    }
  }
}
    `;
export const useCreateSeriesSatelliteDataQuery = <
      TData = CreateSeriesSatelliteDataQuery,
      TError = unknown
    >(
      variables?: CreateSeriesSatelliteDataQueryVariables,
      options?: UseQueryOptions<CreateSeriesSatelliteDataQuery, TError, TData>
    ) =>
    useQuery<CreateSeriesSatelliteDataQuery, TError, TData>(
      variables === undefined ? ['createSeriesSatelliteData'] : ['createSeriesSatelliteData', variables],
      fetcher<CreateSeriesSatelliteDataQuery, CreateSeriesSatelliteDataQueryVariables>(CreateSeriesSatelliteDataDocument, variables),
      options
    );
export const useInfiniteCreateSeriesSatelliteDataQuery = <
      TData = CreateSeriesSatelliteDataQuery,
      TError = unknown
    >(
      variables?: CreateSeriesSatelliteDataQueryVariables,
      options?: UseInfiniteQueryOptions<CreateSeriesSatelliteDataQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<CreateSeriesSatelliteDataQuery, TError, TData>(
      variables === undefined ? ['createSeriesSatelliteData.infinite'] : ['createSeriesSatelliteData.infinite', variables],
      (metaData) => fetcher<CreateSeriesSatelliteDataQuery, CreateSeriesSatelliteDataQueryVariables>(CreateSeriesSatelliteDataDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const EpisodeGenerationStatusDocument = `
    query episodeGenerationStatus($input: EpisodeGenerationStatusInput!) {
  episodeGenerationStatus(input: $input) {
    job {
      episodeId
      seriesId
      id
      status
    }
    error
  }
}
    `;
export const useEpisodeGenerationStatusQuery = <
      TData = EpisodeGenerationStatusQuery,
      TError = unknown
    >(
      variables: EpisodeGenerationStatusQueryVariables,
      options?: UseQueryOptions<EpisodeGenerationStatusQuery, TError, TData>
    ) =>
    useQuery<EpisodeGenerationStatusQuery, TError, TData>(
      ['episodeGenerationStatus', variables],
      fetcher<EpisodeGenerationStatusQuery, EpisodeGenerationStatusQueryVariables>(EpisodeGenerationStatusDocument, variables),
      options
    );
export const useInfiniteEpisodeGenerationStatusQuery = <
      TData = EpisodeGenerationStatusQuery,
      TError = unknown
    >(
      variables: EpisodeGenerationStatusQueryVariables,
      options?: UseInfiniteQueryOptions<EpisodeGenerationStatusQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<EpisodeGenerationStatusQuery, TError, TData>(
      ['episodeGenerationStatus.infinite', variables],
      (metaData) => fetcher<EpisodeGenerationStatusQuery, EpisodeGenerationStatusQueryVariables>(EpisodeGenerationStatusDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const MaestroEpisodesDocument = `
    query maestroEpisodes($input: MaestroEpisodesQueryInput!) {
  maestroEpisodes(input: $input) {
    episodes {
      ...StudioEpisode
    }
    metadata {
      hasMore
      nextCursor {
        offset
        limit
      }
    }
    error {
      message
      code
    }
  }
}
    ${StudioEpisodeFragmentDoc}`;
export const useMaestroEpisodesQuery = <
      TData = MaestroEpisodesQuery,
      TError = unknown
    >(
      variables: MaestroEpisodesQueryVariables,
      options?: UseQueryOptions<MaestroEpisodesQuery, TError, TData>
    ) =>
    useQuery<MaestroEpisodesQuery, TError, TData>(
      ['maestroEpisodes', variables],
      fetcher<MaestroEpisodesQuery, MaestroEpisodesQueryVariables>(MaestroEpisodesDocument, variables),
      options
    );
export const useInfiniteMaestroEpisodesQuery = <
      TData = MaestroEpisodesQuery,
      TError = unknown
    >(
      variables: MaestroEpisodesQueryVariables,
      options?: UseInfiniteQueryOptions<MaestroEpisodesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<MaestroEpisodesQuery, TError, TData>(
      ['maestroEpisodes.infinite', variables],
      (metaData) => fetcher<MaestroEpisodesQuery, MaestroEpisodesQueryVariables>(MaestroEpisodesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const MaestroSeriesDocument = `
    query maestroSeries($input: MaestroSeriesQueryInput!) {
  maestroSeries(input: $input) {
    series {
      ...MaestroSeries
    }
    error {
      message
      code
    }
  }
}
    ${MaestroSeriesFragmentDoc}`;
export const useMaestroSeriesQuery = <
      TData = MaestroSeriesQuery,
      TError = unknown
    >(
      variables: MaestroSeriesQueryVariables,
      options?: UseQueryOptions<MaestroSeriesQuery, TError, TData>
    ) =>
    useQuery<MaestroSeriesQuery, TError, TData>(
      ['maestroSeries', variables],
      fetcher<MaestroSeriesQuery, MaestroSeriesQueryVariables>(MaestroSeriesDocument, variables),
      options
    );
export const useInfiniteMaestroSeriesQuery = <
      TData = MaestroSeriesQuery,
      TError = unknown
    >(
      variables: MaestroSeriesQueryVariables,
      options?: UseInfiniteQueryOptions<MaestroSeriesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<MaestroSeriesQuery, TError, TData>(
      ['maestroSeries.infinite', variables],
      (metaData) => fetcher<MaestroSeriesQuery, MaestroSeriesQueryVariables>(MaestroSeriesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const MaestroDiscoveryDocument = `
    query maestroDiscovery($input: DiscoveryInput, $seriesInput: SeriesQueryInput) {
  series(input: $seriesInput) {
    series {
      ...SeriesShort
    }
  }
  storyFactoryBranches(filter: {onlyPublished: true}) {
    ...StoryFactoryBranchShort
  }
  discovery(input: $input) {
    sections {
      ...DiscoverySection
    }
    error {
      message
      code
    }
  }
}
    ${SeriesShortFragmentDoc}
${StoryFactoryBranchShortFragmentDoc}
${DiscoverySectionFragmentDoc}
${DiscoverySectionSeriesFragmentDoc}`;
export const useMaestroDiscoveryQuery = <
      TData = MaestroDiscoveryQuery,
      TError = unknown
    >(
      variables?: MaestroDiscoveryQueryVariables,
      options?: UseQueryOptions<MaestroDiscoveryQuery, TError, TData>
    ) =>
    useQuery<MaestroDiscoveryQuery, TError, TData>(
      variables === undefined ? ['maestroDiscovery'] : ['maestroDiscovery', variables],
      fetcher<MaestroDiscoveryQuery, MaestroDiscoveryQueryVariables>(MaestroDiscoveryDocument, variables),
      options
    );
export const useInfiniteMaestroDiscoveryQuery = <
      TData = MaestroDiscoveryQuery,
      TError = unknown
    >(
      variables?: MaestroDiscoveryQueryVariables,
      options?: UseInfiniteQueryOptions<MaestroDiscoveryQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<MaestroDiscoveryQuery, TError, TData>(
      variables === undefined ? ['maestroDiscovery.infinite'] : ['maestroDiscovery.infinite', variables],
      (metaData) => fetcher<MaestroDiscoveryQuery, MaestroDiscoveryQueryVariables>(MaestroDiscoveryDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const PrePublishEpisodeVersionValidationDocument = `
    query prePublishEpisodeVersionValidation($input: PrePublishEpisodeVersionValidationInput!) {
  prePublishEpisodeVersionValidation(input: $input) {
    status
    episodeValidations {
      field
      message
    }
    seriesValidations {
      field
      message
    }
    error {
      message
      code
    }
  }
}
    `;
export const usePrePublishEpisodeVersionValidationQuery = <
      TData = PrePublishEpisodeVersionValidationQuery,
      TError = unknown
    >(
      variables: PrePublishEpisodeVersionValidationQueryVariables,
      options?: UseQueryOptions<PrePublishEpisodeVersionValidationQuery, TError, TData>
    ) =>
    useQuery<PrePublishEpisodeVersionValidationQuery, TError, TData>(
      ['prePublishEpisodeVersionValidation', variables],
      fetcher<PrePublishEpisodeVersionValidationQuery, PrePublishEpisodeVersionValidationQueryVariables>(PrePublishEpisodeVersionValidationDocument, variables),
      options
    );
export const useInfinitePrePublishEpisodeVersionValidationQuery = <
      TData = PrePublishEpisodeVersionValidationQuery,
      TError = unknown
    >(
      variables: PrePublishEpisodeVersionValidationQueryVariables,
      options?: UseInfiniteQueryOptions<PrePublishEpisodeVersionValidationQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<PrePublishEpisodeVersionValidationQuery, TError, TData>(
      ['prePublishEpisodeVersionValidation.infinite', variables],
      (metaData) => fetcher<PrePublishEpisodeVersionValidationQuery, PrePublishEpisodeVersionValidationQueryVariables>(PrePublishEpisodeVersionValidationDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const ListStoryFactoryDocument = `
    query listStoryFactory {
  storyFactories {
    ...StoryFactoryShort
  }
}
    ${StoryFactoryShortFragmentDoc}`;
export const useListStoryFactoryQuery = <
      TData = ListStoryFactoryQuery,
      TError = unknown
    >(
      variables?: ListStoryFactoryQueryVariables,
      options?: UseQueryOptions<ListStoryFactoryQuery, TError, TData>
    ) =>
    useQuery<ListStoryFactoryQuery, TError, TData>(
      variables === undefined ? ['listStoryFactory'] : ['listStoryFactory', variables],
      fetcher<ListStoryFactoryQuery, ListStoryFactoryQueryVariables>(ListStoryFactoryDocument, variables),
      options
    );
export const useInfiniteListStoryFactoryQuery = <
      TData = ListStoryFactoryQuery,
      TError = unknown
    >(
      variables?: ListStoryFactoryQueryVariables,
      options?: UseInfiniteQueryOptions<ListStoryFactoryQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<ListStoryFactoryQuery, TError, TData>(
      variables === undefined ? ['listStoryFactory.infinite'] : ['listStoryFactory.infinite', variables],
      (metaData) => fetcher<ListStoryFactoryQuery, ListStoryFactoryQueryVariables>(ListStoryFactoryDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const ListStoryFactoryBranchDocument = `
    query listStoryFactoryBranch {
  storyFactoryBranches {
    ...StoryFactoryBranchShort
  }
}
    ${StoryFactoryBranchShortFragmentDoc}`;
export const useListStoryFactoryBranchQuery = <
      TData = ListStoryFactoryBranchQuery,
      TError = unknown
    >(
      variables?: ListStoryFactoryBranchQueryVariables,
      options?: UseQueryOptions<ListStoryFactoryBranchQuery, TError, TData>
    ) =>
    useQuery<ListStoryFactoryBranchQuery, TError, TData>(
      variables === undefined ? ['listStoryFactoryBranch'] : ['listStoryFactoryBranch', variables],
      fetcher<ListStoryFactoryBranchQuery, ListStoryFactoryBranchQueryVariables>(ListStoryFactoryBranchDocument, variables),
      options
    );
export const useInfiniteListStoryFactoryBranchQuery = <
      TData = ListStoryFactoryBranchQuery,
      TError = unknown
    >(
      variables?: ListStoryFactoryBranchQueryVariables,
      options?: UseInfiniteQueryOptions<ListStoryFactoryBranchQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<ListStoryFactoryBranchQuery, TError, TData>(
      variables === undefined ? ['listStoryFactoryBranch.infinite'] : ['listStoryFactoryBranch.infinite', variables],
      (metaData) => fetcher<ListStoryFactoryBranchQuery, ListStoryFactoryBranchQueryVariables>(ListStoryFactoryBranchDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const GetStoryFactoryDocument = `
    query getStoryFactory($id: ID!) {
  storyFactory(id: $id) {
    ...StoryFactory
  }
}
    ${StoryFactoryFragmentDoc}`;
export const useGetStoryFactoryQuery = <
      TData = GetStoryFactoryQuery,
      TError = unknown
    >(
      variables: GetStoryFactoryQueryVariables,
      options?: UseQueryOptions<GetStoryFactoryQuery, TError, TData>
    ) =>
    useQuery<GetStoryFactoryQuery, TError, TData>(
      ['getStoryFactory', variables],
      fetcher<GetStoryFactoryQuery, GetStoryFactoryQueryVariables>(GetStoryFactoryDocument, variables),
      options
    );
export const useInfiniteGetStoryFactoryQuery = <
      TData = GetStoryFactoryQuery,
      TError = unknown
    >(
      variables: GetStoryFactoryQueryVariables,
      options?: UseInfiniteQueryOptions<GetStoryFactoryQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetStoryFactoryQuery, TError, TData>(
      ['getStoryFactory.infinite', variables],
      (metaData) => fetcher<GetStoryFactoryQuery, GetStoryFactoryQueryVariables>(GetStoryFactoryDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const GetStoryFactoryBranchDocument = `
    query getStoryFactoryBranch($id: ID!) {
  storyFactoryBranch(id: $id) {
    ...StoryFactoryBranch
  }
}
    ${StoryFactoryBranchFragmentDoc}
${StoryFactoryFragmentDoc}`;
export const useGetStoryFactoryBranchQuery = <
      TData = GetStoryFactoryBranchQuery,
      TError = unknown
    >(
      variables: GetStoryFactoryBranchQueryVariables,
      options?: UseQueryOptions<GetStoryFactoryBranchQuery, TError, TData>
    ) =>
    useQuery<GetStoryFactoryBranchQuery, TError, TData>(
      ['getStoryFactoryBranch', variables],
      fetcher<GetStoryFactoryBranchQuery, GetStoryFactoryBranchQueryVariables>(GetStoryFactoryBranchDocument, variables),
      options
    );
export const useInfiniteGetStoryFactoryBranchQuery = <
      TData = GetStoryFactoryBranchQuery,
      TError = unknown
    >(
      variables: GetStoryFactoryBranchQueryVariables,
      options?: UseInfiniteQueryOptions<GetStoryFactoryBranchQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetStoryFactoryBranchQuery, TError, TData>(
      ['getStoryFactoryBranch.infinite', variables],
      (metaData) => fetcher<GetStoryFactoryBranchQuery, GetStoryFactoryBranchQueryVariables>(GetStoryFactoryBranchDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const StudioEpisodeDocument = `
    query studioEpisode($input: StudioEpisodeQueryInput!) {
  studioEpisode(input: $input) {
    data {
      ...StudioEpisode
    }
    error {
      message
      code
    }
  }
}
    ${StudioEpisodeFragmentDoc}`;
export const useStudioEpisodeQuery = <
      TData = StudioEpisodeQuery,
      TError = unknown
    >(
      variables: StudioEpisodeQueryVariables,
      options?: UseQueryOptions<StudioEpisodeQuery, TError, TData>
    ) =>
    useQuery<StudioEpisodeQuery, TError, TData>(
      ['studioEpisode', variables],
      fetcher<StudioEpisodeQuery, StudioEpisodeQueryVariables>(StudioEpisodeDocument, variables),
      options
    );
export const useInfiniteStudioEpisodeQuery = <
      TData = StudioEpisodeQuery,
      TError = unknown
    >(
      variables: StudioEpisodeQueryVariables,
      options?: UseInfiniteQueryOptions<StudioEpisodeQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<StudioEpisodeQuery, TError, TData>(
      ['studioEpisode.infinite', variables],
      (metaData) => fetcher<StudioEpisodeQuery, StudioEpisodeQueryVariables>(StudioEpisodeDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const EpisodeStatusDocument = `
    query episodeStatus($input: StudioEpisodeQueryInput!) {
  studioEpisode(input: $input) {
    data {
      id
      publishedVersion {
        publishedAt
      }
      draftVersion {
        updatedAt
      }
      order
    }
    error {
      message
      code
    }
  }
}
    `;
export const useEpisodeStatusQuery = <
      TData = EpisodeStatusQuery,
      TError = unknown
    >(
      variables: EpisodeStatusQueryVariables,
      options?: UseQueryOptions<EpisodeStatusQuery, TError, TData>
    ) =>
    useQuery<EpisodeStatusQuery, TError, TData>(
      ['episodeStatus', variables],
      fetcher<EpisodeStatusQuery, EpisodeStatusQueryVariables>(EpisodeStatusDocument, variables),
      options
    );
export const useInfiniteEpisodeStatusQuery = <
      TData = EpisodeStatusQuery,
      TError = unknown
    >(
      variables: EpisodeStatusQueryVariables,
      options?: UseInfiniteQueryOptions<EpisodeStatusQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<EpisodeStatusQuery, TError, TData>(
      ['episodeStatus.infinite', variables],
      (metaData) => fetcher<EpisodeStatusQuery, EpisodeStatusQueryVariables>(EpisodeStatusDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};
